import { useRef, useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import {
	IconButton,
	TextField,
	Tooltip,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Collapse,
	Typography,
} from '@mui/material'
import { ClearOutlined, SearchOutlined, RefreshOutlined } from '@mui/icons-material'
import { cloneDeep, isEmpty, uniqBy, orderBy } from 'lodash'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import SkeletonLoaderSidePanel from '../../../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import TileWrapper from '../../components/TileWrapper'
import MobileUnitsSettingsDialog from './MobileUnitsSettingDialog'
import tileApi from 'apis/disApi/tileApi'
import useMobileUnitsQuery from '../../hooks/useMobileUnitsQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { tileKeys } from '../../hooks/useTileQuery'
import useDashboardQuery from '../../hooks/useDashboardQuery'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { useDebounce } from 'utils/hooks/useDebounce'
import { mobileUnitTileFieldIdMap } from 'utils/params/helpers'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { generateRandomID } from '../../helpers'
import { getUserVariables } from 'components/core/services/environmentService'
import { mobileUnitTilePrimaryKeyColumns } from './MobileUnitsForm'

const useStyles = makeStyles(() => ({
	formHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	formMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
		background: '#fff',
	},
	formMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	formMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '40%',
		zIndex: '99999',
	},
}))

function MobileUnitsTile(props) {
	const { tile } = props

	const [t] = useTranslation('common')
	const classes = useStyles()
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const { updateMutation: updateDashboard, dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const tileRef = useRef(null)

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { tileWidth, connectedDatagridKey, primaryKeyList, additionalFields } = settings
	const [searchInput, setSearchInput] = useState('')
	const [displayAdditionalFields, setDisplayAdditionalFields] = useState([])

	// data states
	const [collapseGroupKeys, setCollapseGroupKeys] = useState([])
	const { isLoading, data: groupedData, refetchData } = useMobileUnitsQuery({ tile, searchInput })
	const { onSelectMobileUnit, resetSelectedMobileNumber } = useTileDashboard()
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [userVariables, setUserVariabless] = useState([])
	const [selectedDevice, setSelectedDevice] = useState({})
	const [mappedGroupedData, setMappedGroupedData] = useState([])
	useEffect(() => {
		const fetchUserVariables = async () => {
			try {
				if (userVariables.length > 0) {
					return
				}
				const result = await getUserVariables(environment.apiToken)
				setUserVariabless(result?.data || [])
			} catch (error) {
				logErrorMessage(error)
			}
		}
		fetchUserVariables()
	}, [environment.apiToken, userVariables])
	
	const combinedPrimaryKeyColumns = useMemo(() => {
		const userVariableFields = userVariables.map((variable, index) => ({
			title: variable.title,
			name: `Variable${index + 1}`,
			mobileUnitKey: `variable${index + 1}`,
			hide: false,
		}))
		return [...mobileUnitTilePrimaryKeyColumns, ...userVariableFields]
	}, [userVariables])

	useEffect(() => {
		if (additionalFields?.length > 0) {
			setDisplayAdditionalFields(additionalFields)
		} else {
			setDisplayAdditionalFields(['Mobile_number', 'Nickname'])
		}
	}, [ additionalFields])

	function handleSelectDevice(device) {
		if (selectedDevice?.key === device?.key) {
			setSelectedDevice({})
			return
		}
		setSelectedDevice(device)
	}

	const handleCollapse = (teamKey) => {
		const found = collapseGroupKeys.includes(teamKey)
		if (!found) {
			setCollapseGroupKeys([...collapseGroupKeys, teamKey])
		} else {
			setCollapseGroupKeys(collapseGroupKeys.filter((item) => item !== teamKey))
		}
	}

	const inputRef = useRef()
	const debounceSearchInput = useDebounce(searchInput, 500)

	function onSearchChange(event) {
		event.preventDefault()
		setSearchInput(event.target.value)
	}

	const onSearchClear = (e) => {
		e.preventDefault()
		inputRef.current.value = ''
		setSearchInput('')
	}

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { primaryKeyList, connectedDatagridKey, additionalFields } = data
			const currentDashboardKeyList = cloneDeep(dashboardKeyList)
			const tileName = tile.i
			const tileKey = tile.key
			const otherDashboardKeys = currentDashboardKeyList.filter(
				(item) => item.tileKey !== tileKey && item.tileName !== tileName
			)
			const primaryKeysToSave = [...otherDashboardKeys]
			if (!isEmpty(primaryKeyList)) {
				const newPrimaryKeyList = [
					...primaryKeyList.map((item) => ({
						id: generateRandomID(),
						key: item,
						tileName,
						tileKey,
						mobileUnitKey: mobileUnitTileFieldIdMap[item],
					})),
				]
				const uniqNewPrimaryKeyList = uniqBy(
					newPrimaryKeyList,
					(item) => `${item.key}-${tile.tileName}-${tile.tileKey}`
				)

				// mark delete primary key
				const deletedPrimaryKeyList = currentDashboardKeyList
					.filter((item) => tileName === item.tileName && tileKey === item.tileKey)
					.map((oldItem) => {
						const hasInNewList = uniqNewPrimaryKeyList.find(
							(newItem) =>
								oldItem.key === newItem.key &&
								oldItem.tileName === newItem.tileName &&
								oldItem.tileKey === newItem.tileKey
						)

						if (hasInNewList) return null

						return {
							...oldItem,
							deleted: true,
						}
					})
					.filter((item) => !!item)

				const allTileKeys = [...uniqNewPrimaryKeyList, ...deletedPrimaryKeyList]

				const keepOldPrimaryKeyList = allTileKeys
					.map((item) => {
						const deletedKey = deletedPrimaryKeyList.find(
							(deletedItem) =>
								deletedItem.key === item.key &&
								deletedItem.tileName === item.tileName &&
								deletedItem.tileKey === item.tileKey
						)
						if (Boolean(deletedKey)) {
							return null
						}

						const oldDashboardKeyItem = currentDashboardKeyList.find(
							(oldItem) =>
								oldItem.key === item.key &&
								oldItem.tileName === item.tileName &&
								oldItem.tileKey === item.tileKey
						)
						if (Boolean(oldDashboardKeyItem)) {
							return { ...oldDashboardKeyItem, old: true }
						}
						return item
					})
					.filter((item) => !!item)

				primaryKeysToSave.push(...keepOldPrimaryKeyList)
			}

			const orderedSaveList = orderBy(
				primaryKeysToSave,
				(item) => `${item.tileName}-${item.tileKey}-${item.id}`
			)

			await Promise.all([
				updateDashboard.mutateAsync({
					key: dashboardKey,
					data: {
						variables: JSON.stringify(orderedSaveList),
						additionalFields,
					},
					token: environment.apiToken,
				}),
				updateTileMutation.mutateAsync({
					dashboardKey,
					tileKey: tile.key,
					data: {
						settings: JSON.stringify({
							...settings,
							connectedDatagridKey,
							additionalFields,
						}),
					},
					token: environment.apiToken,
				}),
			])
		} catch (error) {
			logErrorMessage(error)
		} finally {
			resetSelectedMobileNumber()
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const formatTimeDifference = (lastUpdated) => {
		const now = new Date()
		const diffInMs = now - new Date(lastUpdated)
		const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))

		if (diffInHours > 24) {
			const diffInDays = Math.floor(diffInHours / 24)
			return `${diffInDays}d`
		} else {
			return `${diffInHours} h`
		}
	}
	const getAddressFromGeocode = (latitude, longitude) => {
		return new Promise((resolve, reject) => {
			const location = new window.google.maps.LatLng(latitude, longitude)
			const geocoder = new window.google.maps.Geocoder()

			geocoder.geocode({ location: location }, (res, status) => {
				if (status === 'OK' && res?.length) {
					resolve(res[0].formatted_address)
				} else {
					reject('')
				}
			})
		})
	}
	const buildMapDevice = async (device) => {
		const mapDevice = {}
		if (!displayAdditionalFields) return mapDevice
		for (const field of displayAdditionalFields) {
			switch (field) {
				case 'Nickname':
					mapDevice['nickname'] = device.name || device.number
					break

				case 'Mobile_number':
					mapDevice['number'] = device.number || ''
					break

				case 'Gps_status':
					mapDevice['gpsStatus'] = device.lastTrack?.status || ''
					mapDevice['gpsUpdateTime'] = device.lastTrack?.date || ''
					break

				case 'Address':
					if (device.lastTrack?.position) {
						const address = await getAddressFromGeocode(
							device.lastTrack.position.latitude,
							device.lastTrack.position.longitude
						)
						mapDevice['address'] = address
					} else {
						mapDevice['address'] = ''
					}
					break
				case 'Timesheet_status':
					mapDevice['timesheetStatus'] = device.timesheet?.status || ''
					mapDevice['timesheetNote'] = device.timesheet?.note || ''
					mapDevice['timesheetDate'] = device.timesheet?.date || ''
					break

				default: {
					const normalizedKey = field.toLowerCase()
					const foundKey = Object.keys(device).find((k) => k.toLowerCase() === normalizedKey)
					mapDevice[normalizedKey] = foundKey ? device[foundKey] : ''
					break
				}
			}
		}
		return mapDevice
	}

	useEffect(() => {
		const processDevices = async () => {
			if (!groupedData) return

			const updated = await Promise.all(
				groupedData.map(async (group) => ({
					...group,
					devices: await Promise.all(
						group.devices.map(async (device) => ({
							...device,
							mapped: await buildMapDevice(device),
						}))
					),
				}))
			)

			setMappedGroupedData(updated)
		}

		processDevices()
	}, [groupedData, displayAdditionalFields])

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<MobileUnitsSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					dashboardKeyList={dashboardKeyList}
					environment={environment}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<div style={{ display: 'flex' }}>
						<TextField
							id="search-input"
							ref={inputRef}
							sx={{ mt: 0.5, mb: 0.5, pl: 1, pr: 1 }}
							InputLabelProps={{ shrink: false }}
							placeholder={t('common:misc.search')}
							size="small"
							fullWidth
							value={searchInput}
							onChange={onSearchChange}
							InputProps={{
								endAdornment: debounceSearchInput ? (
									<IconButton aria-label="clear" size="small" edge="end" onClick={onSearchClear}>
										<ClearOutlined fontSize="inherit" />
									</IconButton>
								) : (
									<IconButton aria-label="clear" size="small" edge="end" disabled>
										<SearchOutlined fontSize="inherit" />
									</IconButton>
								),
							}}
						/>
						<Tooltip
							title={`${t('tooltip.refresh')}`}
							arrow
							placement="bottom-start"
							disableInteractive
						>
							<span>
								<IconButton
									aria-label="refresh"
									size="small"
									className={classes.button}
									sx={{ p: 2 }}
									onClick={() => {
										setMappedGroupedData([])
										refetchData()
									}}
								>
									<RefreshOutlined fontSize="inherit" className={classes.icon} />
								</IconButton>
							</span>
						</Tooltip>
					</div>
					<div className={classes.formMenuListContainer}>
						{isLoading && <SkeletonLoaderSidePanel />}
						<List
							className={`${classes.formMenuList} ${
								isLoading ? t('common:misc.loading').toLowerCase() : ''
							}`}
						>
							{mappedGroupedData?.map((data, index) => {
								return (
									<ListItem key={data.teamName} disablePadding>
										<ListItemButton
											className={classes.formHeading}
											onClick={() => handleCollapse(data.teamKey)}
										>
											<ListItemText primary={data.teamName} />
										</ListItemButton>
										<Collapse
											id={index}
											in={!collapseGroupKeys.includes(data.teamKey)}
											timeout="auto"
											unmountOnExit
										>
											<List className={classes.formMenuChildList} disablePadding>
												{data.devices?.map((device) => {
													const mappedDevice = device.mapped
													const allVariableWithValue = combinedPrimaryKeyColumns
														.filter((column) => column.name.startsWith('Variable'))
														.map((column) => ({
															title: column.title,
															value: mappedDevice[column.mobileUnitKey] || '',
														}))
														.filter((item) => item.value)

													return (
														<ListItem
															key={device.key}
															className={classes.formItem}
															style={
																device?.key === selectedDevice?.key
																	? { backgroundColor: 'rgba(0,0,0,0.3)' }
																	: {}
															}
														>
															<ListItemButton
																className={classes.formItemBtn}
																onClick={() => {
																	onSelectMobileUnit(tile.key, connectedDatagridKey, device)
																	handleSelectDevice(device)
																}}
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	flexWrap: 'nowrap',
																}}
															>
																<div style={{ flex: '1 1 15%' }}>
																	{mappedDevice.nickname && (
																		<ListItemText
																			primary={
																				<span>{mappedDevice.nickname || mappedDevice.number}</span>
																			}
																			secondary={<span>{mappedDevice.number}</span>}
																		/>
																	)}
																</div>

																<div style={{ flex: '1 1 10%' }}>
																	{mappedDevice.timesheetStatus && (
																		<ListItemText
																			primary={
																				<Tooltip title={mappedDevice.timesheetNote}>
																					<span>{mappedDevice.timesheetStatus}</span>
																				</Tooltip>
																			}
																			secondary={
																				<span>
																					{formatTimeDifference(mappedDevice.timesheetDate)}
																				</span>
																			}
																		/>
																	)}
																</div>

																<div style={{ flex: '1 1 10%' }}>
																	{mappedDevice.gpsStatus && (
																		<ListItemText
																			primary={
																				<Tooltip title={formatTimeDifference(mappedDevice.gpsUpdateTime)}>
																					<span>{mappedDevice.gpsStatus}</span>
																				</Tooltip>
																			}
																			secondary={
																				<span>
																					{formatTimeDifference(mappedDevice.gpsUpdateTime)}
																				</span>
																			}
																		/>
																	)}
																</div>

																<div style={{ flex: '1 1 30%'}}>
																	{mappedDevice.address && (
																		<ListItemText
																			primary={
																				<Tooltip title={formatTimeDifference(mappedDevice.gpsUpdateTime)}>
																					<span
																						style={{
																							whiteSpace: 'normal',
																							wordBreak: 'break-word',
																						}}
																					>
																						{mappedDevice.address}
																					</span>
																				</Tooltip>
																				
																			}
																		/>
																	)}
																</div>

																{allVariableWithValue.length > 0 && <div style={{ flex: '1 1 35%' }}>
																	{allVariableWithValue.length > 0 &&
																		allVariableWithValue.map((item, index) => (
																			<div key={index} style={{ marginBottom: 4 }}>
																				<Typography
																					variant="body1"
																					color="text.secondary"
																					component="span"
																				>
																					{item.title}:
																				</Typography>{' '}
																				<Typography
																					variant="body1"
																					color="text.primary"
																					component="span"
																				>
																					{item.value}
																				</Typography>
																			</div>
																		))}
																</div>}
															</ListItemButton>
														</ListItem>
													)
												})}
											</List>
										</Collapse>
									</ListItem>
								)
							})}
						</List>
					</div>
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default MobileUnitsTile
