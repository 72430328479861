import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { getAllTeams } from '../../../../components/data/dataServices'
import { getDevices } from '../../../core/services/environmentService'
import { useState, useEffect, useMemo } from 'react'
import { isEmpty, sortBy } from 'lodash'

const mobileUnitsKey = {
	allTeams: 'teams',
	allDevices: 'devices',
}

function useMobileUnitsQuery(props) {
	const { tile, searchInput } = props
	const { environment } = useSelector((state) => state)

	const [data, setData] = useState([])

	const {
		data: teamsData,
		isLoading: isLoadingTeams,
		isFetching: isFetchingTeams,
		refetch: refetchTeamsData,
	} = useQuery(
		['mobileUnits', tile.key, mobileUnitsKey.allTeams],
		() => getAllTeams(environment.apiToken),
		{
			enabled: Boolean(tile.key),
		}
	)

	const {
		data: devicesData,
		isLoading: isLoadingDevices,
		isFetching: isFetchingDevices,
		refetch: refetchDevicesData,
	} = useQuery(
		['mobileUnits', tile.key, mobileUnitsKey.allDevices],
		() => getDevices(environment.apiToken),
		{
			enabled: Boolean(tile.key),
		}
	)

	const loadingData = useMemo(() => {
		return isFetchingTeams || isLoadingTeams || isLoadingDevices || isFetchingDevices
	}, [isLoadingTeams, isFetchingTeams, isFetchingDevices, isLoadingDevices])

	const filterDevices = useMemo(() => {
		if (loadingData) return []
		const devices = devicesData?.data
		if (isEmpty(devices)) return []
		return devices.filter((device) =>
			device.name?.toLowerCase().includes(searchInput.toLowerCase())
		)
	}, [loadingData, searchInput, devicesData?.data])

	useEffect(() => {
		if (loadingData) return []
		let teams = sortBy(teamsData.data, (team) => team.name.toLowerCase())

		const result = teams.map((team) => ({
			teamKey: team.key,
			teamName: team.name,
			devices: filterDevices.filter((device) => device.teamKey === team.key),
		}))

		const noTeamDevices = filterDevices.filter((device) => {
			const deviceTeam = teams.find((team) => device.teamKey === team.key)
			return !deviceTeam
		})
		if (!isEmpty(noTeamDevices)) {
			result.push({
				teamKeu: 'noGroup',
				teamName: 'No Group',
				devices: noTeamDevices,
			})
		}

		setData(result.filter((item) => !isEmpty(item.devices)))
	}, [loadingData, teamsData, filterDevices])

	function refetchData() {
		refetchTeamsData()
		refetchDevicesData()
	}

	return {
		mobileUnitsGroups: teamsData?.data ?? [],
		isLoading: isLoadingTeams || isFetchingTeams || isLoadingDevices || isFetchingDevices,
		data,
		refetchData,
	}
}

export default useMobileUnitsQuery
