import { Box, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import _, { isEmpty } from 'lodash'
import { useCallback, useMemo, useRef, useState, useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useForceRerender from 'utils/hooks/useForceRerender'
import LoadingSpinner from '../../../../../custom-components/LoadingSpinner'
import { IconThemeProvider } from '../../../../../custom-components/context/IconThemesContext'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import DoformsGridChart from '../../../../data/datagrid/DoformsGridChart'
import TileWrapper from '../../components/TileWrapper'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { getAutoUpdateFilters, getLinkedTitle } from '../../helpers'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import ChartSettingsDialog from './ChartSettingsDialog'
import { checkByMasterDateTimeCondition } from 'utils/functions/helpers'

const CHART_DATA_ACTION = {
	VIEW_DATA: 'viewData',
	GRID_ROWS: 'gridRows',
	COLUMNS: 'columns',
	INIT_DATA: 'initdata',
}

function chartDataReducer(state, action) {
	switch (action.type) {
		case CHART_DATA_ACTION.VIEW_DATA:
			return { ...state, viewData: action.payload }
		case CHART_DATA_ACTION.GRID_ROWS:
			return { ...state, rows: action.payload }
		case CHART_DATA_ACTION.COLUMNS:
			return { ...state, columns: action.payload }
		case CHART_DATA_ACTION.INIT_DATA:
			return {
				viewData: action.payload.viewData,
				rows: action.payload.gridRows,
				columns: action.payload.columns,
			}
		default:
			return state
	}
}

const ChartTile = ({ tile }) => {
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const queryClient = useQueryClient()

	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const { selectedFields, linkedTitle } = useTileDashboard()

	const updateMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { linkedFields = {} } = settings.mapTileInfo || {}

	const [forceId, setForceRerender] = useForceRerender()
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [chartSetting, setChartSetting] = useState(tile.chart)
	const [isChartLoading, setIsChartLoading] = useState(false)

	const [chartData, dispatchChartData] = useReducer(chartDataReducer, {})

	const { filterConfigs, conditions } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields, dashboardKeyList]
	)

	const { linkedSuffixTitle } = useMemo(
		() => getLinkedTitle(linkedFields, selectedFields, dashboardKeyList, linkedTitle),
		[linkedFields, selectedFields, linkedTitle]
	)

	useEffect(() => {
		setChartSetting(tile.chart)
	}, [tile.chart])

	const getFilteredRows = (rows, filterConfigs, conditions) => {
		if (_.isEmpty(rows)) return []

		return rows.filter((row) => {
			if (filterConfigs?.length > 0) {
				return filterConfigs.every((filter) => {
					if (row) {
						const valueColumnField = row?.[filter.field] ?? ''

						return valueColumnField?.toLowerCase()?.includes(filter.value?.toLowerCase()) ?? false
					}
					return false
				})
			}
			return true
		})
	}

	const filteredRow = useMemo(() => {
		return getFilteredRows(chartData?.rows, filterConfigs, conditions)
	}, [chartData?.rows, filterConfigs, conditions])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const tileRef = useRef(null)
	const formMapRef = useRef(null)

	const handleSubmitSetting = async ({
		viewKey,
		formKey,
		projectKey,
		linked,
		// rows,
		// columns,
		chartSetting: newChartSetting,
		otherOptionsChecked,
	}) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				otherOptionsChecked,
				mapTileInfo: {
					viewKey,
					formKey,
					projectKey,
					linkedFields: linked,
				},
			})

			// const a = {
			// 	chart: chartSetting,
			// 	settings: editedSettings,
			// }

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { chart: newChartSetting, settings: editedSettings },
				token: environment.apiToken,
			})

			// if (chartSetting) {
			// 	await tileApi.update({
			// 		dashboardKey,
			// 		tileKey: tile.key,
			// 		data: { chart: chartSetting },
			// 		token: environment.apiToken,
			// 	})
			// }

			// dispatchChartData({
			// 	type: CHART_DATA_ACTION.INIT_DATA,
			// 	payload: {
			// 		rows,
			// 		columns
			// 	}
			// })

			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const onHandleFilters = (params, xAxisSelected, dateGroupSelected) => {
		setForceRerender()
	}

	const isShowResizeSlider = () => {
		let isShow = settings?.otherOptionsChecked?.includes('showResizeSlider') ? true : false
		if (
			isShow === false &&
			settings?.otherOptionsChecked?.includes('isNewShowResizeSlider') === false
		) {
			isShow = true
		}
		return isShow
	}

	const renderChart = useCallback(() => {
		if (!chartSetting?.display) return null

		let isShow = settings?.otherOptionsChecked?.includes('showResizeSlider') ? true : false
		if (
			isShow === false &&
			settings?.otherOptionsChecked?.includes('isNewShowResizeSlider') === false
		) {
			isShow = true
		}

		if (filteredRow?.length === 0)
			return (
				<Box
					sx={{
						height: '50%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography variant="h6" color="text.secondary">
						Chart no data
					</Typography>
				</Box>
			)

		return (
			<DoformsGridChart
				id={'MapTileGridChart'}
				formMapRef={formMapRef}
				environment={environment}
				columns={chartData?.columns ?? []}
				gridRows={filteredRow ?? []}
				chartSetting={chartSetting || {}}
				onHandleFilters={onHandleFilters}
				showResizeSlider={
					// settings?.otherOptionsChecked?.includes('showResizeSlider') ? true : false
					isShow
				}
				hiddenDisplayChartBtn
				hiddenDisplayEditChartBtn
			/>
		)
	}, [filteredRow, chartSetting, chartData?.columns])

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={isEmpty(linkedSuffixTitle) ? tile?.i : `${tile?.i} ${linkedSuffixTitle}`}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
			>
				{isChartLoading && <LoadingSpinner />}
				{!_.isEmpty(chartSetting) && (
					<Box
						ref={formMapRef}
						sx={{
							height: '100%',
							width: '100%',
							backgroundColor: 'white',
							p: 2,
						}}
					>
						{renderChart()}
					</Box>
				)}

				<ChartSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={settings.tileWidth}
					tile={tile}
					open={settingsOpen}
					settings={settings}
					chartSetting={chartSetting}
					setChartSetting={setChartSetting}
					isChartLoading={isChartLoading}
					setIsChartLoading={setIsChartLoading}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					masterDateTimeConditions={conditions}
					dispatchChartData={dispatchChartData}
					showResizeSlider={
						//settings?.otherOptionsChecked?.includes('showResizeSlider') ? true : false
						isShowResizeSlider
					}
				/>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default ChartTile
