import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isEqual, debounce } from 'lodash'
import { useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { logErrorMessage } from '../../../../../utils/functions/helpers'

import { loadNextRecordsQuery, loadRecordsQuery } from 'components/data/dataServices'

export function useRecordQuery(props) {
	const { viewKey, clientFilter, viewType } = props

	const { environment } = useSelector((state) => state)

	const queryClient = useQueryClient()
	const [queryFilter, setQueryFilter] = useState({})
	const [cancelLoadAllRecords, setCancelLoadAllRecords] = useState(false)

	useEffect(() => {
		if (isEqual(clientFilter, queryFilter)) return
		setQueryFilter(clientFilter)
	}, [clientFilter])

	const queryKey = useMemo(() => {
		const clientFilterString = !queryFilter ? '' : JSON.stringify(queryFilter)
		if (!viewKey || !clientFilterString) return null

		return ['records-query', viewKey, queryFilter]
	}, [viewKey, queryFilter])

	useEffect(() => {
		return () => {
			setCancelLoadAllRecords(false)
			if (!isEmpty(queryKey)) {
				queryClient.removeQueries({ queryKey: queryKey })
			}
		}
	}, [queryKey])

	function fetchRecords({ pageParam = null }) {
		try {
			const viewSession = false
			if (isEmpty(pageParam)) {
				return loadRecordsQuery(viewKey, viewSession, clientFilter, environment.apiToken, viewType)
			} else {
				return loadNextRecordsQuery(pageParam, viewSession, environment.apiToken, viewType)
			}
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const { data, isFetching, isLoading, refetch, hasNextPage, isFetchingNextPage, fetchNextPage } =
		useInfiniteQuery({
			queryKey, // Query key
			queryFn: fetchRecords,
			// Use `nextUrl` from the API response to determine the next page
			getNextPageParam: (lastPage) => {
				const view = lastPage?.data?.view

				// Stop fetching if `view` is missing or invalid
				if (!view || !view.more) return undefined

				// Update the first query's filter with `clientFilter`
				const updatedQueries = [...view.queries]
				updatedQueries[0] = {
					...updatedQueries[0],
					filter: { ...clientFilter },
				}

				const queryView = { ...view, queries: updatedQueries }

				// Return the updated `queryView` as `pageParam`
				return queryView
			},
			enabled: !!queryKey && !!queryFilter,
		})

	useEffect(() => {
		if (cancelLoadAllRecords) return

		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	}, [cancelLoadAllRecords, hasNextPage, isFetchingNextPage, fetchNextPage])

	const cancelNextPage = () => {
		setCancelLoadAllRecords(true)
	}

	const debouncedRefetch = debounce(() => {
		refetch()
	}, 1000)

	return {
		records: data?.pages?.flatMap((page) => page?.data?.records) || [],
		recordsLoading: isLoading || isFetching || isFetchingNextPage,
		onCancelLoadingRecords: cancelNextPage,
		hasMoreRecords: cancelLoadAllRecords === true ? false : hasNextPage,
		refetchRecords: debouncedRefetch,
		setQueryFilter,
	}
}
