import { useState, useMemo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { tileKeys } from '../../hooks/useTileQuery'
import useDashboardQuery from '../../hooks/useDashboardQuery'

import tileApi from 'apis/disApi/tileApi'
import doFormMapApi from 'apis/disApi/doFormMapApi'

import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'

import ToastAlert from '../../../../core/Layouts/ToastAlert'
import TileWrapper from '../../components/TileWrapper'
import MapSettingsDialog from '../map/MapSettingsDialog'
import DoformsMapInfo from './DoformsMapInfo'

function DoformsMapTile(props) {
	const { tile } = props
	const environment = useSelector((state) => state.environment)
	const queryClient = useQueryClient()
	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const updateTile = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})
	const formMapRef = useRef(null)
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})
	const [selectedDeviceInfo, setSelectedDeviceInfo] = useState({})
	const [titleSuffix, setTitleSuffix] = useState('')

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const {
		locationData = {},
		showFilters = false,
		showHistory = true,
		pointOfInterest = false,
		linkedFields = {},
		repeatReloadMapSeconds,
		connectedDatagridKey: connectedMobileUnitKey,
	} = settings

	const {
		data: devices,
		isLoading: deviceLoading,
		isFetching: deviceFetching,
	} = useQuery({
		queryKey: ['doFormsMap', 'devices'],
		queryFn: async () => {
			const devicesResponse = await doFormMapApi.devices(environment.apiToken)
			return devicesResponse.data
		},
		retry: false,
	})
	const isDevicesLoading = useMemo(
		() => deviceLoading || deviceFetching,
		[deviceLoading, deviceFetching]
	)

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleSubmit = async (data) => {
		try {
			const {
				locationData,
				showFilters,
				showHistory,
				pointOfInterest,
				linkedFields,
				repeatReloadMapSeconds,
				connectedDatagridKey,
			} = data
			const { locationData: locationFromSettings, ...rest } = settings
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...rest,
						showFilters: showFilters,
						showHistory: showHistory,
						pointOfInterest: pointOfInterest,
						repeatReloadMapSeconds,
						locationData: {
							...locationFromSettings,
							...locationData,
						},
						linkedFields,
						connectedDatagridKey,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	useEffect(() => {
		const isShowTitleSuffix = !isEmpty(selectedDeviceInfo) && showFilters
		const title = isShowTitleSuffix ? selectedDeviceInfo?.name || selectedDeviceInfo?.number : ''
		setTitleSuffix(title)
	}, [tile?.i, selectedDeviceInfo, showFilters])

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
			<TileWrapper
				title={isEmpty(titleSuffix) ? tile?.i : `${tile?.i}  ${titleSuffix}`}
				onSettingClick={handleOpenDialog}
				ref={formMapRef}
				tooltipValue={selectedDeviceInfo?.number || ''}
			>
				<DoformsMapInfo
					tileKey={tile?.key}
					devices={devices}
					deviceLoading={isDevicesLoading}
					showFilters={showFilters}
					repeatReloadMapSeconds={repeatReloadMapSeconds}
					showHistory={showHistory}
					pointOfInterest={pointOfInterest}
					locationData={locationData}
					linkedFields={linkedFields}
					dashboardKey={dashboardKey}
					dashboardKeyList={dashboardKeyList}
					connectedMobileUnitKey={connectedMobileUnitKey}
					setSelectedDeviceInfo={setSelectedDeviceInfo}
				/>
				<MapSettingsDialog
					defaultTileWidth={300}
					tileElementWidth={formMapRef?.current?.clientWidth}
					open={settingsOpen}
					tile={tile}
					isSubmitting={updateTile?.isLoading}
					locationData={locationData}
					connectedMobileUnitKey={connectedMobileUnitKey}
					showFilters={showFilters}
					repeatReloadMapSeconds={repeatReloadMapSeconds}
					showHistory={showHistory}
					pointOfInterest={pointOfInterest}
					dashboardKeyList={dashboardKeyList}
					linkedFields={linkedFields}
					onClose={handleCloseDialog}
					onResizeTileWidth={handleResizeTileWidth}
					onSubmit={handleSubmit}
					dashboardKey={dashboardKey}
				/>
			</TileWrapper>
		</>
	)
}

export default DoformsMapTile
