export const mobileUnitTileFieldIdMap = {
	Mobile_number: 'number',
	Nickname: 'name',
}

export const GEOFENCES_TYPES = {
	SHARED_GEOFENCES: 'account',
	MOBILE_UNIT_GEOFENCES: 'device',
}

export const GEOFENCES_RENDER_TYPES = {
	CIRCLE: 'circle',
	POLYGON: 'polygon',
}
