import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { isEmpty, sortBy } from 'lodash'

import '../../../styles.css'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import GeofencesDashboard from './GeofencesDashboard'
import GeofencesData from './GeofencesData'

import { EnvironmentProvider } from '../../../custom-components/context/EnvironmentContext'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'

import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { GEOFENCES_ACTIONS } from '../../../reducers/geofencesReducer'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { geofenceApi } from '../../../apis/disApi/viewWizard/geofenceApi'

const useStyles = makeStyles(() => ({
	geofenceSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	geofenceBody: {
		display: 'contents',
	},
}))

const GeofencesModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()
	const actionQuery = urlQueryParam.get('action')
	const classes = useStyles()

	const { environment, geofencesModule } = useSelector((state) => state)
	const iconTheme = environment.theme.icons

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const dispatch = useDispatch()
	const isMounted = useRef(true)

	const initiateLoadAllGeofences = () => {
		setLoading(true)
		loadAllGeofences()
	}

	useEffect(() => {
		if (!environment.refresh) return
		initiateLoadAllGeofences()
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: false,
		})
	}, [environment.refresh])

	function loadAllGeofences() {
		Promise.all([
			geofenceApi.getSharedGeofences(environment.apiToken),
			geofenceApi.getMobileUnitGeofences(environment.apiToken),
		])
			.then((res) => {
				const [sharedGeofencesResponse, mobileUnitGeofencesResponse] = res
				const sharedGeofences = sharedGeofencesResponse.data || []
				const mobileUnitGeofences = mobileUnitGeofencesResponse.data || []
				dispatch({
					type: ENV_ACTIONS.GET_GEOFENCES,
					payload: [...sharedGeofences, ...mobileUnitGeofences],
				})
				dispatch({
					type: GEOFENCES_ACTIONS.SET_INIT_GEOFENCES,
					payload: {
						sharedGeofences: sharedGeofences,
						mobileUnitGeofences: mobileUnitGeofences,
					},
				})
				setLoading(false)
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
	}

	useEffect(() => {
		if (isEmpty(environment?.geofences)) {
			initiateLoadAllGeofences()
		}
	}, [])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const sidePanel = () => (
		<div id="geofence-side-panel" className={classes.geofenceSidePanel}>
			<EnvironmentProvider values={environment}>
				<GeofencesDashboard module={geofencesModule} loading={loading} />
			</EnvironmentProvider>
		</div>
	)

	const geofenceBody = () => (
		<div id="geofence-body" className={classes.geofenceBody}>
			{showErrorMessage()}
			<GeofencesData environment={environment} module={geofencesModule} />
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={geofenceBody()} />
		</IconThemeProvider>
	)
}

export default GeofencesModule
