import { Box, Stack } from '@mui/material'
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import moment from 'moment/moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { convertKmHToMph, checkByMasterDateTimeCondition } from 'utils/functions/helpers'
import LoadingSpinner from '../../../../../custom-components/LoadingSpinner'
import arrowIcon from '../../../../../static/img/arrow-up.png'
import stopIcon from '../../../../../static/img/stop.png'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { getAutoUpdateFilters } from '../../helpers'
import useDoformsMapDeviceLocation from '../../hooks/doformMapTile/useDoformsMapDeviceLocation'
import useDoformsMapCurrentDeviceQuery from '../../hooks/doformMapTile/useDoformsMapCurrentDeviceQuery'
import DoformsMapFilters from './DoformsMapFilters'
import { isEmpty, isNumber, sortBy, cloneDeep } from 'lodash'

const svgIcons = {
	currentLocation: {
		path: 'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z',
		color: '#575fcf',
		borderColor: '#1e272e',
		scale: 0.06,
		pointX: 180,
		pointY: 600,
	},
	arrow: {
		url: arrowIcon,
	},
	exclamation: {
		path: 'M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z',
		color: '#fff200',
		borderColor: '#ff3838',
		scale: 1.3,
		pointX: 15,
		pointY: 15,
	},
}

const COLUMN_NAME = {
	FROM_DATE: 'fromDate',
	TO_DATE: 'toDate',
	DEVICE_NAME: 'deviceName',
	DEVICE_NUMBER: 'deviceNumber',
}

function convertArrayToObject(arr) {
	const output = {}

	for (let item of arr) {
		const { field, value } = item
		output[field] = value
	}
	return output
}

function formatDuration(stopDuration) {
	// Parse the time string into a Moment object
	const duration = moment.duration(stopDuration)

	// Format the time as "X days Y hours Z minutes"
	const formattedDuration = duration.humanize()

	return formattedDuration
}

const defaultMapState = {
	center: { lat: 33.753746, lng: -84.38633 },
	zoom: 11,
}

function rotateImage(inputImage, rotationDegree) {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	// Determine the size of the canvas to fit the image after rotation
	const width = inputImage.width
	const height = inputImage.height

	canvas.width = width
	canvas.height = height

	// Rotate the image and draw it on the canvas
	ctx.translate(width / 2, height / 2) // Move the focus to the center of the image
	ctx.rotate((rotationDegree * Math.PI) / 180) // Rotate by rotation angle (convert to radians)
	ctx.drawImage(inputImage, -width / 2, -height / 2, width, height) // Draw pictures

	// Convert canvas to rotated image
	const rotatedImage = new Image()
	rotatedImage.src = canvas.toDataURL('image/png')

	return rotatedImage
}

export default function DoformsMapInfo({
	tileKey,
	locationData,
	showFilters,
	repeatReloadMapSeconds,
	showHistory,
	pointOfInterest,
	linkedFields,
	dashboardKeyList,
	dashboardKey,
	connectedMobileUnitKey,
	devices,
	setSelectedDeviceInfo,
}) {
	const [activeMarker, setActiveMarker] = useState(null)
	const [mapRef, setMapRef] = useState(null)
	const [fromDate, setFromDate] = useState(locationData.fromDate || null)
	const [toDate, setToDate] = useState(locationData.toDate || null)

	const [deviceKey, setDeviceKey] = useState(null)
	const [deviceName, setDeviceName] = useState(null)

	const [center, setCenter] = useState(defaultMapState.center)
	const [zoom, setZoom] = useState(defaultMapState.zoom)

	const { selectedFields, isRemovedTile } = useTileDashboard()
	let isFitBounds = false

	const { filterConfigs, hasPrimaryKey, conditions } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields, dashboardKeyList]
	)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	// Remove id because it is random property and make app re-render many times
	const conditionsWithoutId = useMemo(() => {
		const result = {}
		for (const key in conditions) {
			const value = conditions[key]
			const { id, ...rest } = value
			result[key] = rest
		}
		return result
	}, [conditions])

	const { deviceTracking, stopLocations } = useDoformsMapDeviceLocation({
		tileKey,
		deviceKey,
		beginDate: fromDate,
		endDate: toDate,
		showHistory,
	})

	const {
		data: currentVehicleLocation,
		isLoading,
		isFetching,
	} = useDoformsMapCurrentDeviceQuery({
		tileKey: tileKey,
		deviceKey: deviceKey,
		apiCallIntervalInSeconds: repeatReloadMapSeconds,
		beginDate: fromDate,
		endDate: toDate,
	})

	const sortedLocationByDate = useMemo(() => {
		if (!deviceTracking || isEmpty(deviceTracking)) return []

		return sortBy(
			deviceTracking?.filter((item) => item.position?.latitude && item.position?.longitude),
			(obj) => moment.utc(obj.dateTime)
		).map((device) => {
			const { latitude, longitude } = device.position
			return {
				lat: latitude,
				lng: longitude,
			}
		})
	}, [deviceTracking])

	useEffect(() => {
		setFromDate(locationData?.fromDate ?? new Date())
	}, [locationData?.fromDate])

	useEffect(() => {
		setToDate(locationData?.toDate)
	}, [locationData?.toDate])

	useEffect(() => {
		if (hasPrimaryKey) {
			const {
				Mobile_number: deviceNumber,
				Nickname: deviceName,
				fromDate: newFromDate,
				toDate: newToDate,
			} = convertArrayToObject(filterWithoutId)

			const currentDevice = devices?.find((device) => {
				if (deviceNumber && deviceName) {
					return device.number === deviceNumber && device.name === deviceName
				}
				if (deviceName) {
					return device.name === deviceName
				}

				return device.number === deviceNumber
			})

			let newDeviceNumber = !currentDevice ? '' : currentDevice.key
			let fromDate = newFromDate || locationData?.fromDate || null
			let toDate = newToDate || locationData?.toDate || null
			const valueToCheck = {
				fromDate,
				toDate,
				deviceName,
				deviceNumber: newDeviceNumber,
			}

			if (!isEmpty(conditionsWithoutId)) {
				Object.values(COLUMN_NAME).forEach((key) => {
					const condition = conditionsWithoutId[key]
					if (isEmpty(condition)) return

					const dateValue = valueToCheck[key]
					const isValid = checkByMasterDateTimeCondition(
						dateValue,
						condition,
						condition?.keyData === 'Master_DateTime'
					)
					if (!isValid) {
						valueToCheck[key] = ''
					}
				})
			}

			setSelectedDeviceInfo(currentDevice)
			setFromDate(valueToCheck.fromDate)
			setToDate(valueToCheck.toDate)
			setDeviceKey(valueToCheck.deviceNumber)
			setDeviceName(valueToCheck.deviceName)
		} else {
			setDeviceKey('')
			setDeviceName('')
			setFromDate(locationData?.fromDate)
			setToDate(locationData?.toDate)
			setSelectedDeviceInfo({})
		}
	}, [JSON.stringify(filterWithoutId), JSON.stringify(conditionsWithoutId), hasPrimaryKey, devices])

	const fitBounds = useCallback(
		(map) => {
			if (!map) return

			const bounds = new window.google.maps.LatLngBounds()
			sortedLocationByDate.forEach((latLngItem) => {
				bounds.extend(latLngItem)
			})
			map.fitBounds(bounds)

			// Override zoom again
			// map.setZoom(defaultMapState.zoom)

			const centerFromBounds = bounds.getCenter()
			setCenter(centerFromBounds)
			//setZoom(defaultMapState.zoom)
		},
		[sortedLocationByDate]
	)

	useEffect(() => {
		isFitBounds = false
		if (!mapRef) return
		if (isEmpty(currentVehicleLocation) && isEmpty(deviceTracking)) {
			setCenter(defaultMapState.center)
			setZoom(defaultMapState.zoom)
			mapRef?.setZoom(defaultMapState.zoom)
			return
		}
		if (currentVehicleLocation) {
			const { latitude, longitude } = currentVehicleLocation?.[0]?.position ?? {}
			if (!isNumber(latitude) || !isNumber(longitude)) {
				fitBounds(mapRef)
				isFitBounds = true
				return
			}

			const zoom = mapRef?.getZoom() || defaultMapState.zoom
			setCenter({ lat: latitude, lng: longitude })
			setZoom(zoom)
		} else {
			fitBounds(mapRef)
		}
	}, [
		mapRef,
		fitBounds,
		showHistory,
		JSON.stringify(deviceTracking),
		JSON.stringify(currentVehicleLocation),
	])

	useEffect(() => {
		if (!mapRef) return
		// Reset zoom to default when change vehicle
		if (isFitBounds === false) {
			setZoom(defaultMapState.zoom)
			mapRef?.setZoom(defaultMapState.zoom)
		}
	}, [deviceKey])

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return
		}
		setActiveMarker(marker)
	}

	const handleOnLoad = (map) => {
		if (!map) return

		// Store a reference to the google map instance in state
		setMapRef(map)
	}

	return (
		<Stack
			sx={{
				height: '100%',
				width: '100%',
				mt: '6px !important',
			}}
		>
			{(isLoading || isFetching) && <LoadingSpinner />}
			{showFilters && (
				<Stack px={1} direction="row" alignItems="center" spacing={2}>
					<DoformsMapFilters
						dashboardKey={dashboardKey}
						fromDate={fromDate ?? new Date()}
						toDate={toDate}
						disableFilters
						connectedDatagridKey={connectedMobileUnitKey}
					/>
				</Stack>
			)}

			{!isRemovedTile?.[tileKey] && (
				<Box sx={{ flex: 1, mt: 1 }}>
					<GoogleMap
						ref={mapRef}
						onLoad={handleOnLoad}
						center={center}
						zoom={zoom}
						onClick={() => setActiveMarker(null)}
						mapContainerStyle={{
							height: '100%',
							width: '100%',
						}}
						options={{
							minZoom: 4,
							gestureHandling: 'greedy',
							styles: [
								{
									featureType: 'poi',
									stylers: [{ visibility: pointOfInterest ? 'on' : 'off' }],
								},
							],
						}}
					>
						{sortedLocationByDate?.length > 0 && (
							<Polyline
								path={sortedLocationByDate}
								options={{
									strokeColor: 'rgba(134, 0, 191, 0.9)',
									strokeOpacity: 0.8,
									strokeWeight: 5,
									geodesic: true,
									icons: [
										{
											icon: {
												path: window.google?.maps?.SymbolPath?.FORWARD_CLOSED_ARROW || '',
												strokeWeight: 2,
												fillColor: '#fefefe',
												fillOpacity: 1,
												strokeOpacity: 1,
												strokeColor: '#fefefe',
												scale: 1,
												labelOrigin: new window.google.maps.Point(0, 0),
												rotation: 0,
											},
											offset: '100%',
											repeat: '60px',
										},
									],
								}}
							/>
						)}

						{currentVehicleLocation?.length > 0 &&
							currentVehicleLocation.map((value, index) => {
								if (isEmpty(value?.position)) return null
								const { position: devicePosition, velocity, date } = value

								const { latitude, longitude, currentStateDuration } = devicePosition

								const isDeviceCommunicating = true
								const isDriving = false
								const bearing = velocity?.bearing || 0
								const speed = velocity?.speed || 0

								const formattedDate = moment(date).format('MM/DD/YY [at] hh:mm:ss A')

								const hasPosition = latitude && longitude
								const position = { lat: latitude, lng: longitude }

								let icon
								const shouldShowArrow = isDriving && bearing !== -1
								// If metric is true, use km/h, otherwise use mph
								const isMetric = true

								if (!isDeviceCommunicating) {
									icon = svgIcons.exclamation
								} else if (shouldShowArrow) {
									icon = svgIcons.arrow
								} else {
									icon = svgIcons.currentLocation
								}

								const inputImage = new Image()
								inputImage.src = icon?.url

								return (
									<Marker
										key={index}
										position={position}
										onClick={() => handleActiveMarker(index)}
										zIndex={100}
										icon={
											icon?.url
												? {
														url: inputImage?.src ? rotateImage(inputImage, bearing).src : icon?.url,

														scaledSize: new window.google.maps.Size(28, 28),
														anchor: new window.google.maps.Point(15, 15),
												  }
												: {
														path: icon.path, // Use the rectangle path
														fillColor: icon.color,
														strokeColor: icon.borderColor, // Border color
														scale: icon.scale, // Adjust the scale as needed
														rotation: shouldShowArrow ? bearing : undefined,
														fillOpacity: 1,
														strokeWeight: 1, // Border thickness
														scaledSize: new window.google.maps.Size(30, 30),
														anchor: new window.google.maps.Point(icon.pointX, icon.pointY), // Anchor point at the center of the icon
												  }
										}
									>
										{/* Fix open popover issue when clicking on a marker */}
										{/* @see: https://stackoverflow.com/questions/48719430/not-able-to-render-the-infowindow-in-react-google-maps */}
										{hasPosition && activeMarker === index ? (
											<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
												{isDriving ? (
													<div>
														<p>{formattedDate}</p>
														{deviceName && (
															<p>
																<span style={{ fontWeight: 'bold' }}>Vehicle: </span>
																{deviceName}
															</p>
														)}
														<p>
															<span style={{ fontWeight: 'bold' }}>Driving: </span>
															{formatDuration(currentStateDuration)}
														</p>
														<p>
															<span style={{ fontWeight: 'bold' }}>Speed: </span>
															{isMetric ? `${speed} km/h` : `${convertKmHToMph(speed)} mph`}
														</p>
													</div>
												) : (
													<div>
														<p>{formattedDate}</p>
														{deviceName && (
															<p>
																<span style={{ fontWeight: 'bold' }}>Vehicle: </span>
																{deviceName}
															</p>
														)}
														<p>
															<span style={{ fontWeight: 'bold' }}>Stopped: </span>
															{formatDuration(currentStateDuration)}
														</p>
													</div>
												)}
											</InfoWindow>
										) : null}
									</Marker>
								)
							})}

						{stopLocations?.length > 0 &&
							stopLocations.map((value, index) => {
								if (isEmpty(value?.position)) return null
								const { position: devicePosition, date } = value

								const { latitude, longitude } = devicePosition
								const formattedStopDate = moment(date).format('MM/DD/YY [at] hh:mm:ss A')

								const markerId = index
								const hasPosition = latitude && longitude
								const position = { lat: latitude, lng: longitude }

								return (
									<Marker
										key={markerId}
										position={position}
										onClick={() => handleActiveMarker(markerId)}
										icon={{
											url: stopIcon,

											scaledSize: new window.google.maps.Size(20, 20),
											anchor: new window.google.maps.Point(0, 10),
										}}
									>
										{/* Fix open popover issue when clicking on a marker */}
										{/* @see: https://stackoverflow.com/questions/48719430/not-able-to-render-the-infowindow-in-react-google-maps */}
										{hasPosition && activeMarker === markerId ? (
											<InfoWindow position={position} onCloseClick={() => setActiveMarker(null)}>
												<div>
													<p>
														<span style={{ fontWeight: 'bold' }}>Stop time: </span>
														{formattedStopDate}
													</p>
												</div>
											</InfoWindow>
										) : null}
									</Marker>
								)
							})}
					</GoogleMap>
				</Box>
			)}
		</Stack>
	)
}
