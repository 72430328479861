import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { getAppointments } from 'components/core/services/environmentService'
import { ref, off, onValue } from 'firebase/database'
import { isEmpty, debounce } from 'lodash'
import moment from 'moment'

import database from 'firebase-config'
import { sleep } from '../../../../utils/functions/helpers'

export const currentSchedulerKeys = {
	all: ['scheduler-tile'],
	detailAll: (tileKey, date, range) => [...currentSchedulerKeys.all, tileKey, date, range],
	detailDevice: (tileKey, date, range, deviceKey) => [
		...currentSchedulerKeys.all,
		tileKey,
		date,
		range,
		deviceKey,
	],
}

function useSchedulerQuery({ tileKey, rangeType, selectedDevice, selectedDateDisplay }) {
	const { environment } = useSelector((state) => state)
	const [schedulerRef, setSchedulerCountRef] = useState('')
	const selectDate = moment(selectedDateDisplay).format('YYYY-MM-DD')
	const queryKey = useMemo(() => {
		if (!selectedDevice || isEmpty(selectedDevice))
			return currentSchedulerKeys.detailAll(tileKey, selectDate, rangeType)
		return currentSchedulerKeys.detailDevice(tileKey, selectDate, rangeType, selectedDevice.key)
	}, [tileKey, rangeType, selectedDevice, selectDate])

	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: queryKey,
		queryFn: () =>
			getAppointments(environment.apiToken, selectDate, rangeType, selectedDevice?.key),
		enabled: Boolean(rangeType),
		staleTime: 5000,
	})

	useEffect(() => {
		const customerKey = environment.account.key
		if (!customerKey) return

		if (!isEmpty(schedulerRef)) {
			off(schedulerRef)
		}

		setSchedulerCountRef(ref(database, `userSessions/customerKey/${customerKey}`))
	}, [environment.account.key])

	const debouncedRefetch = debounce(() => {
		refetch()
	}, 500)

	useEffect(() => {
		if (isEmpty(schedulerRef)) return

		const unSubscribeSchedulerOffset = onValue(schedulerRef, (snapshot) => {
			const data = snapshot.val()
			if (!data) return
			sleep(2000).then(() => {
				debouncedRefetch()
			})
		})

		return () => {
			unSubscribeSchedulerOffset()
		}
	}, [schedulerRef])

	return { appoinments: data, isLoadingData: isLoading || isFetching, refetch }
}

export default useSchedulerQuery
