import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, List, FormControl, Stack, InputLabel, Select, MenuItem } from '@mui/material'

export default function DatagridTileLabel(props) {
	const { title, firstLabel, secondLabel, fields, handleSettingsChange } = props

	const [t] = useTranslation('common')
	return (
		<div>
			<Typography variant="subtitle2" sx={{ pl: 1 }}>
				{title}
			</Typography>
			<List
				sx={{
					width: '100%',
					overflowY: 'none',
					border: '1px solid #ddd',
					borderRadius: 1,
					mb: 2,
				}}
				dense
			>
				<Stack direction="row" alignItems="center" sx={{ my: 1, marginTop: '-10px' }}>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120, flex: 1 }} size="small">
						<InputLabel id="first-select-small-label">{t('common:dis.firstLabel')}</InputLabel>
						<Select
							labelId="first-select-small-label"
							id="first-select-small"
							value={firstLabel ?? ''}
							label={t('common:dis.firstLabel')}
							onChange={(e) => {
								handleSettingsChange('firstLabel', e.target.value)
							}}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{fields?.length > 0 &&
								fields.map((item) => (
									<MenuItem value={item.name} key={item.name}>
										{item.title}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Stack>
				<Stack
					direction="row"
					alignItems="center"
					sx={{ my: 1, marginTop: '-15px', marginBottom: '-1px' }}
				>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120, flex: 1 }} size="small">
						<InputLabel id="second-select-small-label">{t('common:dis.secondLabel')}</InputLabel>
						<Select
							labelId="second-select-small-label"
							id="second-select-small"
							value={secondLabel ?? ''}
							label={t('common:dis.secondLabel')}
							onChange={(e) => {
								handleSettingsChange('secondLabel', e.target.value)
							}}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{fields?.length > 0 &&
								fields.map((item) => (
									<MenuItem value={item.name} key={item.name}>
										{item.title}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Stack>
			</List>
		</div>
	)
}
