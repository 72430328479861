import { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { isEmpty, unionBy } from 'lodash'
import { Autocomplete } from '@react-google-maps/api'

import {
	Box,
	FormControl,
	InputLabel,
	Select,
	TextField,
	MenuItem,
	Typography,
	Slider,
	Stack,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'

import DoformsMessage from '../../../custom-components/DoformsMessage'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import GeofencesMap from './GeofencesMap'

import useGetPathName from 'utils/hooks/useGetPathName'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import { geofenceApi } from 'apis/disApi/viewWizard/geofenceApi'

import { GEOFENCES_TYPES, GEOFENCES_RENDER_TYPES } from '../../../utils/params/helpers'
import { GEOFENCES_ACTIONS, GEOFENCES_ACTIONS_TYPE } from 'reducers/geofencesReducer'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	datagrid: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '30px',
		padding: '8px',
	},
	requiredSuffix: {
		'& .MuiFormLabel-asterisk': {
			color: 'red',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DEFAULT_GEOFENCE_TYPE = GEOFENCES_TYPES.SHARED_GEOFENCES
const DEFAULT_GEOFENCE_RENDER_TYPE = GEOFENCES_RENDER_TYPES.CIRCLE

const formatLatLngValue = (value) => {
	if (typeof value !== 'number' || isNaN(value)) return ''

	// Check if value is within valid lat/lng range
	const isValidLat = value >= -90 && value <= 90
	const isValidLng = value >= -180 && value <= 180

	return isValidLat || isValidLng ? value.toFixed(7) : ''
}

const GeofencesData = ({ environment, module }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const apiToken = environment.apiToken
	const isFirstRender = useFirstRender()
	const firstPathName = useGetPathName()

	const {
		action,
		selectedGeofence,
		sharedGeofences,
		mobileUnitGeofences,
		createdGeofence,
		updatedGeofence,
		deletedGeofence,
	} = module
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)

	// geofence settings
	const [type, setType] = useState(DEFAULT_GEOFENCE_TYPE)
	const [deviceKey, setDeviceKey] = useState('')
	const [name, setName] = useState('')
	const [address, setAddress] = useState('')

	const [geofenceRenderType, setGeofenceRenderType] = useState(GEOFENCES_RENDER_TYPES.CIRCLE)

	const [lat, setLat] = useState(undefined)
	const [lng, setLng] = useState(undefined)

	const [polygonPoints, setPolygonPoints] = useState([])
	const [radius, setRadius] = useState(undefined)

	const displayPolygonPoints = useMemo(() => {
		return polygonPoints
			?.map((point) => `{${formatLatLngValue(point.lat)}}, {${formatLatLngValue(point.lon)}}`)
			.join(';')
	}, [polygonPoints])

	const typeList = [
		{ label: 'Shared', value: GEOFENCES_TYPES.SHARED_GEOFENCES },
		{ label: 'Mobile unit', value: GEOFENCES_TYPES.MOBILE_UNIT_GEOFENCES },
	]

	const MAX_NAME_LENGTH = 50

	const deviceListOptions = useMemo(() => {
		if (isEmpty(environment?.devices)) return []
		return environment?.devices?.map((device) => {
			return {
				key: device.key,
				value: device.key,
				label:
					device.name?.length > MAX_NAME_LENGTH
						? `${device.name.slice(0, MAX_NAME_LENGTH)}...`
						: device.name || device.number,
			}
		})
	}, [environment?.devices])

	useEffect(() => {
		if (action === 'addNew') {
			setType(DEFAULT_GEOFENCE_TYPE)
			setDeviceKey('')
			setName('')
			setAddress('')
			setLat(undefined)
			setLng(undefined)
			setGeofenceRenderType(DEFAULT_GEOFENCE_RENDER_TYPE)
			setRadius(0)
			setPolygonPoints([])
			return
		}

		if (action === 'edit') {
			if (isEmpty(selectedGeofence)) return

			setType(selectedGeofence.type)
			setDeviceKey(selectedGeofence.deviceKey)
			setName(selectedGeofence.name)
			setAddress(selectedGeofence.address)
			setLat(selectedGeofence.latitude)
			setLng(selectedGeofence.longitude)
			setGeofenceRenderType(
				!isEmpty(selectedGeofence.points)
					? GEOFENCES_RENDER_TYPES.POLYGON
					: DEFAULT_GEOFENCE_RENDER_TYPE
			)
			setRadius(selectedGeofence.radius)
			setPolygonPoints(selectedGeofence.points)
		}
	}, [action, selectedGeofence])

	useEffect(() => {
		if (isEmpty(createdGeofence)) return
		setInfo(t('common:misc.successfullyCreatedView') + ` ${createdGeofence.name}`)
		const isSharedGeofence = createdGeofence.type === GEOFENCES_TYPES.SHARED_GEOFENCES
		dispatch({
			type: isSharedGeofence
				? GEOFENCES_ACTIONS.SET_SHARED_GEOFENCES
				: GEOFENCES_ACTIONS.SET_MOBILE_UNIT_GEOFENCES,
			payload: isSharedGeofence
				? unionBy(sharedGeofences, [createdGeofence], 'key')
				: unionBy(mobileUnitGeofences, [createdGeofence], 'key'),
		})

		dispatch({ type: GEOFENCES_ACTIONS.CREATE_GEOFENCE, payload: null })
		dispatch({ type: GEOFENCES_ACTIONS.ACTION, payload: GEOFENCES_ACTIONS_TYPE.EDIT })
		dispatch({ type: GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE, payload: createdGeofence })
	}, [createdGeofence])

	useEffect(() => {
		if (isEmpty(updatedGeofence)) return
		setInfo(t('common:misc.successfullyUpdatedView') + ` ${updatedGeofence.name}`)
		const isSharedGeofence = updatedGeofence.type === GEOFENCES_TYPES.SHARED_GEOFENCES
		dispatch({
			type: isSharedGeofence
				? GEOFENCES_ACTIONS.SET_SHARED_GEOFENCES
				: GEOFENCES_ACTIONS.SET_MOBILE_UNIT_GEOFENCES,
			payload: isSharedGeofence
				? sharedGeofences.map((geofence) =>
						geofence.key === updatedGeofence.key ? updatedGeofence : geofence
				  )
				: mobileUnitGeofences.map((geofence) =>
						geofence.key === updatedGeofence.key ? updatedGeofence : geofence
				  ),
		})

		dispatch({ type: GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE, payload: updatedGeofence })
		dispatch({ type: GEOFENCES_ACTIONS.UPDATE_GEOFENCE, payload: null })
	}, [updatedGeofence])

	useEffect(() => {
		if (isEmpty(deletedGeofence)) return
		setInfo(null)
		const infoMessage = `${deletedGeofence.name} ` + t('common:misc.hasBeenDeleted')
		setInfo(infoMessage)
	}, [deletedGeofence])

	const showLoading = () =>
		loading && (
			<>
				<LoadingSpinner />
			</>
		)

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage message={info} severity={'success'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
	}

	const handleMobileUnitChange = (e) => {
		const newDeviceKey = e.target.value
		setDeviceKey(newDeviceKey)
		if (action === GEOFENCES_ACTIONS_TYPE.ADD_NEW) {
			const selectedDeviceName = deviceListOptions.find(
				(options) => options.key === newDeviceKey
			)?.label
			setName(selectedDeviceName)
		}
	}

	const validateGeofenceSettings = (renderType, geofenceSettings) => {
		const { type, deviceKey, name, latitude, longitude, radius, points } = geofenceSettings
		if (type === GEOFENCES_TYPES.MOBILE_UNIT_GEOFENCES && !deviceKey)
			return 'Mobile unit is required.'
		if (!name.trim()) return 'Name is required.'
		if (!latitude) return 'Latitude is required.'
		if (!longitude) return 'Longitude is required.'
		if (renderType === GEOFENCES_RENDER_TYPES.CIRCLE && !radius) return 'Radius is required.'
		if (renderType === GEOFENCES_RENDER_TYPES.POLYGON && isEmpty(points))
			return 'Points is required.'

		if (latitude < -90 || latitude > 90) return 'Latitude must be between -90 and 90.'
		if (lng < -180 || lng > 180) return 'Longitude must be between -180 and 180.'
		if (renderType === GEOFENCES_RENDER_TYPES.CIRCLE && radius <= 0)
			return 'Radius must be greater than 0.'

		return ''
	}

	const onSave = () => {
		const renderType = geofenceRenderType
		const newGeofenceSettings = {
			type,
			deviceKey: type === GEOFENCES_TYPES.SHARED_GEOFENCES ? null : deviceKey,
			name,
			address,
			latitude: lat,
			longitude: lng,
			radius,
			points: polygonPoints,
		}
		const errorMessage = validateGeofenceSettings(renderType, newGeofenceSettings)

		if (errorMessage) {
			setError(errorMessage)
			return
		}

		if (renderType === GEOFENCES_RENDER_TYPES.CIRCLE) {
			delete newGeofenceSettings.points
		} else if (renderType === GEOFENCES_RENDER_TYPES.POLYGON) {
			delete newGeofenceSettings.radius
		}

		setLoading(true)
		switch (action) {
			case GEOFENCES_ACTIONS_TYPE.ADD_NEW:
				geofenceApi
					.createGeofence(environment.apiToken, newGeofenceSettings)
					.then((res) => {
						dispatch({ type: GEOFENCES_ACTIONS.CREATE_GEOFENCE, payload: res?.data })
						setLoading(false)
					})
					.catch((err) => {
						setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
					})
				break
			case GEOFENCES_ACTIONS_TYPE.EDIT:
				geofenceApi
					.updateGeofence(selectedGeofence.key, newGeofenceSettings, environment.apiToken)
					.then((res) => {
						dispatch({ type: GEOFENCES_ACTIONS.UPDATE_GEOFENCE, payload: res?.data })
						setLoading(false)
					})
					.catch((err) => {
						setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
					})
				break
			default:
				break
		}
	}

	const onCancel = () => {
		dispatch({
			type: GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE,
			payload: {},
		})
		dispatch({
			type: GEOFENCES_ACTIONS.ACTION,
			payload: null,
		})
	}

	const handleGeofenceRenderTypeChange = (_, newValue) => {
		setGeofenceRenderType(newValue)
		let settingRenderType = GEOFENCES_RENDER_TYPES.CIRCLE
		if (action === 'edit') {
			if (selectedGeofence.radius) {
				settingRenderType = GEOFENCES_RENDER_TYPES.CIRCLE
			} else if (!isEmpty(selectedGeofence)) {
				settingRenderType = GEOFENCES_RENDER_TYPES.POLYGON
			}
		}

		if (newValue !== settingRenderType) {
			if (newValue === GEOFENCES_RENDER_TYPES.POLYGON) {
				setLat(undefined)
				setLng(undefined)
			}

			setAddress('')
			setRadius(0)
			setPolygonPoints([])
			return
		}

		setAddress(selectedGeofence.address)
		setLat(selectedGeofence.latitude)
		setLng(selectedGeofence.longitude)
		setRadius(selectedGeofence.radius)
		setPolygonPoints(selectedGeofence.points)
	}

	return (
		[GEOFENCES_ACTIONS_TYPE.ADD_NEW, GEOFENCES_ACTIONS_TYPE.EDIT].includes(action) && (
			<div className={classes.root}>
				{showLoading()}
				{showErrorMessage()}
				{showInfoMessage()}
				<Box display="flex" gap={2} p={2} sx={{ width: '100%', height: '100%' }}>
					<Box width="30%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						<Box display="flex" gap={2}>
							<LoadingButton
								className={classes.icon}
								loadingPosition="start"
								onClick={onSave}
								startIcon={<SaveIcon />}
								loading={loading}
							>
								{t('common:misc.save')}
							</LoadingButton>
							<LoadingButton className={classes.icon} loadingPosition="start" onClick={onCancel}>
								{t('common:misc.cancel')}
							</LoadingButton>
						</Box>
						<FormControl variant="outlined" size="small" fullWidth>
							<InputLabel className={classes.requiredSuffix} id="type-select-label" required>
								Type
							</InputLabel>
							<Select
								id="type-select"
								labelId="type-select-label"
								label={t('common:input.type' + ' *')}
								value={type || ''}
								onChange={(e) => setType(e.target.value)}
								disabled={action === GEOFENCES_ACTIONS_TYPE.EDIT}
							>
								{typeList.map((item) => (
									<MenuItem value={item.value}>{item.label}</MenuItem>
								))}
							</Select>
						</FormControl>

						{type === 'device' && (
							<FormControl variant="outlined" size="small" fullWidth>
								<InputLabel id="mobile-unit-select-label">Mobile unit</InputLabel>
								<Select
									id="mobile-unit-select"
									labelId="mobile-unit-select-label"
									label={t('common:misc.mobileUnit')}
									value={deviceKey || ''}
									onChange={handleMobileUnitChange}
									MenuProps={{
										PaperProps: {
											style: {
												width: 'auto', // Makes it fit the Select width
												maxHeight: '500px', // Prevents it from being too long
											},
										},
									}}
								>
									{deviceListOptions.map((item) => (
										<MenuItem key={item.key} value={item.value}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}

						<TextField
							size="small"
							label={t('common:input.name')}
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							InputLabelProps={{ shrink: true, className: classes.requiredSuffix }}
							required
						/>

						<AddressInput
							address={address}
							setAddress={setAddress}
							setLat={setLat}
							setLng={setLng}
							setError={setError}
							label={t('common:misc.address')}
						/>

						<TextField
							size="small"
							label={t('common:misc.latitude')}
							value={lat !== undefined ? lat.toFixed(7) : ''}
							fullWidth
							disabled
							InputLabelProps={{ shrink: true, className: classes.requiredSuffix }}
							required
						/>
						<TextField
							size="small"
							label={t('common:misc.longitude')}
							value={lng !== undefined ? lng.toFixed(7) : ''}
							disabled
							fullWidth
							InputLabelProps={{ shrink: true, className: classes.requiredSuffix }}
							required
						/>

						<FormControl fullWidth>
							<RadioGroup
								row
								name="row-radio-buttons-group"
								value={geofenceRenderType}
								onChange={handleGeofenceRenderTypeChange}
							>
								<FormControlLabel
									value={GEOFENCES_RENDER_TYPES.CIRCLE}
									control={<Radio />}
									label={t('common:misc.circle')}
								/>
								<FormControlLabel
									value={GEOFENCES_RENDER_TYPES.POLYGON}
									control={<Radio />}
									label={t('common:misc.polygon')}
								/>
							</RadioGroup>
						</FormControl>
						{geofenceRenderType === GEOFENCES_RENDER_TYPES.CIRCLE && (
							<TextField
								size="small"
								label={t('common:misc.radiusM')}
								type="number"
								value={radius || ''}
								onChange={(e) => setRadius(Number(e.target.value))}
								fullWidth
								InputLabelProps={{ shrink: true, className: classes.requiredSuffix }}
								required
							/>
						)}

						{geofenceRenderType === GEOFENCES_RENDER_TYPES.POLYGON && (
							<TextField
								label={t('common:misc.points')}
								value={displayPolygonPoints}
								size="small"
								disabled
								fullWidth
								multiline
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					</Box>

					{/* Right Side - Map */}
					<Box width="70%">
						<GeofencesMap
							geofenceRenderType={geofenceRenderType}
							lat={lat}
							lng={lng}
							radius={radius}
							polygonPoints={polygonPoints}
							setLat={setLat}
							setLng={setLng}
							setRadius={setRadius}
							setPolygonPoints={setPolygonPoints}
						/>
					</Box>
				</Box>
			</div>
		)
	)
}

const AddressInput = ({ address, setAddress, setLat, setLng, setError, label }) => {
	const autocompleteRef = useRef(null)

	const onPlaceChanged = () => {
		if (autocompleteRef.current) {
			const place = autocompleteRef.current.getPlace()
			if (place && place.geometry && place.geometry.location) {
				setAddress(place.formatted_address)
				setLat(place.geometry.location.lat())
				setLng(place.geometry.location.lng())
			} else {
				setError('No valid address found.')
			}
		}
	}
	return (
		<Autocomplete
			onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
			onPlaceChanged={onPlaceChanged}
		>
			<TextField
				size="small"
				label={label}
				value={address}
				onChange={(e) => setAddress(e.target.value)}
				fullWidth
				InputLabelProps={{ shrink: true }}
			/>
		</Autocomplete>
	)
}

export default GeofencesData
