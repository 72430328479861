import { apiUrl } from '../../../apis/apiUrl'
import axios from 'axios'
import { computeOwnerNameByType } from '../../data/dataHelpers'
import moment from 'moment'

const url = apiUrl()

export const getCoreData = async (token, t) => {
	const [account, theme, userCurrent, devices, owners, translations, links, settings] =
		await Promise.allSettled([
			getAccount(token),
			getTheme(token),
			getUserCurrent(token),
			getDevices(token),
			getOwners(token),
			getTranslations(token),
			getLinks(),
			getSettings(token),
		])
	const responseData = {}

	if (account?.status === 'fulfilled') {
		responseData.account = account?.value?.data
	}

	if (theme?.status === 'fulfilled') {
		responseData.theme = theme?.value?.data
	}

	if (userCurrent?.status === 'fulfilled') {
		responseData.userCurrent = userCurrent?.value?.data
	}

	if (devices?.status === 'fulfilled') {
		responseData.devices = devices?.value?.data
	}

	if (owners?.status === 'fulfilled') {
		responseData.owners =
			owners?.value?.data.length &&
			owners?.value?.data.map((owner) => ({
				...owner,
				name: owner.name ? owner.name : computeOwnerNameByType(owner.type, t),
			}))
	}

	if (translations?.status === 'fulfilled') {
		responseData.translations = translations?.value?.data
	}

	if (links?.status === 'fulfilled') {
		responseData.links = links?.value?.data
	}

	if (settings?.status === 'fulfilled') {
		responseData.settings = settings?.value?.data
	}

	return {
		account: responseData?.account ?? {},
		theme: responseData?.theme ?? {},
		userCurrent: responseData?.userCurrent ?? {},
		devices: responseData?.devices ?? {},
		owners: responseData?.owners ?? [],
		translations: responseData?.translations ?? {},
		links: responseData?.links ?? [],
		settings: responseData?.settings ?? {},
	}
}

const getAccount = (token) =>
	axios(`${url}/accounts/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getTheme = (token) =>
	axios(`${url}/themes/portal`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getUserCurrent = (token) =>
	axios(`${url}/users/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

export const getDevices = (token) =>
	axios(`${url}/devices`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getOwners = (token) =>
	axios(`${url}/owners`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getTranslations = (token) =>
	axios(`${url}/translations/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getLinks = () =>
	axios(`${url}/links`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})

export const updateUserCurrent = async (userCurrent, token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.put(`${url}/users/current`, userCurrent, config)
}

const getSettings = (token) =>
	axios(`${url}/settings`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

export const getDispatchSettings = (token) =>
	axios(`${url}/settings/portal/dispatch`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
	
export const getAppointments = (token, date, range, selectedDeviceKey) => {
	let queryUrl = `${url}/appointments?date=${date}&range=${range}`
	if (selectedDeviceKey) {
		queryUrl += `&deviceKey=${selectedDeviceKey}`
	}
	return axios(queryUrl, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export const updateAppointments = async (token, key, data) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.put(`${url}/appointments/${key}`, data, config)
}

export const getUserVariables = async (token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}
	return axios.get(`${url}/settings/uservariables`, config)
}
