import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { unionBy } from 'lodash'
import { FormControl, InputLabel, MenuItem, Select, Skeleton, Stack } from '@mui/material'

import useTileQuery from '../../hooks/useTileQuery'
import { TYPES_OF_TILE } from '../../../../../constants'
import CheckboxList from 'components/pages/dis-v2/_components/CheckboxList'
import { mobileUnitsApi } from 'apis/disApi/viewWizard/mobileUnitsViewApi'
import { logErrorMessage } from 'utils/functions/helpers'
import { getUserVariables } from 'components/core/services/environmentService'

export const mobileUnitTilePrimaryKeyColumns = [
	{
		title: 'Mobile number',
		name: 'Mobile_number',
		mobileUnitKey: 'number',
		hide: false,
	},
	{
		title: 'Nickname',
		name: 'Nickname',
		mobileUnitKey: 'name',
		hide: false,
	},
	{
		title: 'Timesheet Status',
		name: 'Timesheet_status',
		mobileUnitKey: 'timesheetStatus',
		hide: false,
	},
	{
		title: 'GPS Status',
		name: 'Gps_status',
		mobileUnitKey: 'gpsStatus',
		hide: false,
	},
	{
		title: 'Address',
		name: 'Address',
		mobileUnitKey: 'address',
		hide: false,
	},
]

function MobileUnitsForm(props) {
	const {
		dashboardKey,
		filterData,
		enabledFetchTileList = true,
		datagridListWhenCreating = [],
		onFilterDataChange,
		environment,
	} = props

	const { t } = useTranslation('common')

	const defaultCheckedAdditional = ['Mobile_number', 'Nickname']
	const [primaryKeyList, setPrimaryKeyList] = useState([])
	const [additionalFields, setAdditionalFields] = useState([])
	const [userVariables, setUserVariabless] = useState([])
	const [displayAdditionalFields, setDisplayAdditionalFields] = useState([])
	const { tileList, isLoading } = useTileQuery({
		dashboardKey,
		enableConfig: enabledFetchTileList,
	})

	const dataGridTile = useMemo(() => {
		const currentList =
			tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID) : []

		return unionBy(currentList, datagridListWhenCreating, 'name')
	}, [tileList, datagridListWhenCreating])

	const handleSelectChange = (e) => {
		const key = e.target.value
		const name = dataGridTile.find((tile) => tile?.key === key)?.name
		const selectedTile = dataGridTile.find((tile) => tile?.key === key)
		onFilterDataChange('connectedDatagridKey', key)
		if (selectedTile?.is_creating) {
			onFilterDataChange('connectedNameWithNewDatagrid', name)
		}
	}

	useEffect(() => {
		if (filterData?.primaryKeyList) {
			setPrimaryKeyList(filterData?.primaryKeyList)
		}
	}, [filterData?.primaryKeyList])

	useEffect(() => {
		if (filterData?.additionalFields) {
			setAdditionalFields(filterData?.additionalFields)
		} else {
			setAdditionalFields(defaultCheckedAdditional)
			onFilterDataChange('additionalFields', defaultCheckedAdditional)
		}
	}, [filterData?.additionalFields])

	useEffect(() => {
		const fetchUserVariables = async () => {
			try {
				if (userVariables.length > 0) {
					return
				}
				const result = await getUserVariables(environment.apiToken)
				setUserVariabless(result?.data || [])
			} catch (error) {
				logErrorMessage(error)
			}
		}
		fetchUserVariables()
	}, [environment.apiToken, userVariables])

	const combinedPrimaryKeyColumns = useMemo(() => {
		const userVariableFields = userVariables.map((variable, index) => ({
			title: variable.title,
			name: `Variable${index + 1}`,
			mobileUnitKey: `variable${index + 1}`,
			hide: false,
		}))
		return [...mobileUnitTilePrimaryKeyColumns, ...userVariableFields]
	}, [userVariables])

	useEffect(() => {
		const fetchAdditionalFields = async () => {
			try {
				if (displayAdditionalFields.length > 0) {
					return
				}
				const result = await mobileUnitsApi.getColumns(environment.apiToken)
				setDisplayAdditionalFields(result?.data || [])
			} catch (error) {
				logErrorMessage(error)
			}
		}
		fetchAdditionalFields()
	}, [environment.apiToken, displayAdditionalFields])

	const handleSetPrimaryKey = (id) => {
		if (primaryKeyList.includes(id)) {
			onFilterDataChange(
				'primaryKeyList',
				primaryKeyList.filter((item) => item !== id)
			)
		} else {
			onFilterDataChange('primaryKeyList', [...primaryKeyList, id])
		}
	}

	const handleSetAdditionalFields = (checked, id) => {
		if (!checked) {
			const updatedFields = additionalFields.filter((item) => item !== id)
			setAdditionalFields(updatedFields)
			onFilterDataChange('additionalFields', updatedFields)
		} else {
			const updatedFields = [...additionalFields, id]
			setAdditionalFields(updatedFields)
			onFilterDataChange('additionalFields', updatedFields)
		}
	}

	return (
		<Stack spacing={2}>
			{dataGridTile?.length > 0 &&
				(!isLoading ? (
					<FormControl variant="outlined" size="small">
						<InputLabel id="datagrid-select-small-label">
							{t('common:input.datagridToConnect')}
						</InputLabel>
						<Select
							labelId="datagrid-select-small-label"
							id="datagrid-select-small"
							value={filterData?.connectedDatagridKey ?? ''}
							label={t('common:input.datagridToConnect')}
							onChange={handleSelectChange}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{dataGridTile.map((tile) => (
								<MenuItem value={tile?.key} key={tile?.key}>
									{tile?.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<Skeleton variant="rectangular" height={40} />
				))}

			<CheckboxList
				title={t('common:dis.selectFields')}
				enableIcon
				enableCheckbox={true}
				onIconClick={handleSetPrimaryKey}
				checkedIconList={primaryKeyList}
				data={combinedPrimaryKeyColumns}
				onItemClick={() => {}}
				checkedList={additionalFields}
				getLabel={(option) => option.title}
				getValue={(option) => option.name}
				defaultChecked={defaultCheckedAdditional}
				onChange={handleSetAdditionalFields}
			/>
		</Stack>
	)
}

export default MobileUnitsForm
