import { combineReducers } from 'redux'
import environmentReducer from './environmentReducer'
import formsReducer from './formsReducer'
import lookupsReducer from './lookupsReducer'
import viewsReducer from './viewsReducer'
import devicesReducer from './devicesReducer'
import reportsReducer from './reportsReducer'
import activityReducer from './activityReducer'
import geofencesReducer from './geofencesReducer'

export default combineReducers({
	environment: environmentReducer,
	devicesModule: devicesReducer,
	formsModule: formsReducer,
	lookupsModule: lookupsReducer,
	viewsModule: viewsReducer,
	reportsModule: reportsReducer,
	activityModule: activityReducer,
	geofencesModule: geofencesReducer,
})
