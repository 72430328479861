import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'

import {
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Collapse,
	IconButton,
} from '@mui/material'

import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { EnvironmentContext } from '../../../custom-components/context/EnvironmentContext'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsDialog from '../../../custom-components/DoformsDialog'

import { VIEW } from '../../../constants'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { GEOFENCES_ACTIONS, GEOFENCES_ACTIONS_TYPE } from '../../../reducers/geofencesReducer'
import { geofenceApi } from 'apis/disApi/viewWizard/geofenceApi'

const useStyles = makeStyles(() => ({
	geofenceHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	geofenceItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	geofenceItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	geofenceItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	geofencesMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	geofenceMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	geofenceMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '45%',
		zIndex: '99999',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const geofenceCollaspes = [
	{
		name: 'Shared Geofences',
		key: 'sharedGeofences',
	},
	{
		name: 'Mobile Unit Geofences',
		key: 'mobileUnitGeofences',
	},
]

const GeofencesDashboard = (props) => {
	const [t] = useTranslation('common')

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const { module, loading } = props
	const dispatch = useDispatch()

	const { sharedGeofences, mobileUnitGeofences, action, selectedGeofence } = module

	const [collapseProjects, setCollapseProjects] = useState([])
	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [openSubmission, setOpenSubmission] = useState(false)
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	const filteredSharedGeofences = useMemo(() => {
		return sortBy(
			sharedGeofences.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase())),
			(geofence) => geofence.name
		)
	}, [sharedGeofences, searchText])

	const filteredMobileUnitGeofences = useMemo(() => {
		return sortBy(
			mobileUnitGeofences.filter((item) =>
				item.name.toLowerCase().includes(searchText.toLowerCase())
			),
			(geofence) => geofence.name
		)
	}, [mobileUnitGeofences, searchText])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering((prev) => itemName !== newItemName || !prev)
			setItemName(newItemName)
		},
		[]
	)

	const handlePopoverClose = useCallback((e) => {
		e.preventDefault()
		setHovering(false)
	}, [])

	const showItemActions = (geofence) => {
		return (
			itemName === `${geofence.name}` && (
				<ListItemSecondaryAction className={classes.geofenceItemToolbar}>
					<GeofencesDeleteButton geofence={geofence} />
				</ListItemSecondaryAction>
			)
		)
	}
	const handleOpenSubmission = (value) => {
		setOpenSubmission(!!value)
	}

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleCollapse = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const clickHandler = (action, geofence) => (event) => {
		event.preventDefault()
		dispatch({
			type: GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE,
			payload: geofence,
		})
		dispatch({
			type: GEOFENCES_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_GEOFENCES} />
			<div className={classes.geofencesMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.geofenceMenuList} ${
						loading ? t('common:misc.loading').toLowerCase() : ''
					}`}
				>
					{!loading &&
						geofenceCollaspes.length > 0 &&
						geofenceCollaspes.map((collapse, index) => {
							let geofencesList = []
							switch (collapse.key) {
								case 'sharedGeofences':
									geofencesList = filteredSharedGeofences
									break
								case 'mobileUnitGeofences':
									geofencesList = filteredMobileUnitGeofences
									break
								default:
									break
							}

							return (
								<ListItem
									key={collapse.key}
									ContainerProps={{ onMouseLeave: handlePopoverClose }}
									disablePadding
								>
									<ListItemButton
										className={classes.geofenceHeading}
										onClick={() => handleCollapse(collapse.key)}
									>
										<ListItemText primary={collapse.name} />
									</ListItemButton>
									<Collapse
										id={index}
										in={!collapseProjects.includes(collapse.key)}
										timeout="auto"
										unmountOnExit
									>
										<List className={classes.geofenceMenuChildList} disablePadding>
											{geofencesList.map((geofence) => (
												<ListItem
													key={geofence.key}
													className={classes.geofenceItem}
													onMouseOver={handlePopoverOpen(`${geofence.name}`)}
													ContainerProps={{ onMouseLeave: handlePopoverClose }}
												>
													<ListItemButton
														className={classes.geofenceItemBtn}
														disabled={
															selectedGeofence?.key === geofence?.key &&
															action === GEOFENCES_ACTIONS_TYPE.EDIT
														}
														onClick={clickHandler('edit', geofence)}
													>
														<ListItemText
															primary={
																<Tooltip
																	title={geofence.name}
																	arrow
																	placement="bottom-start"
																	disableInteractive
																	enterDelay={1000}
																	enterNextDelay={1000}
																>
																	<span>{geofence.name}</span>
																</Tooltip>
															}
														/>
													</ListItemButton>
													{hovering && showItemActions(geofence)}
												</ListItem>
											))}
										</List>
									</Collapse>
								</ListItem>
							)
						})}
				</List>
			</div>
		</>
	)
}

const GeofencesDeleteButton = (props) => {
	const { geofence, setError } = props
	const [t] = useTranslation('common')
	const { environment, geofencesModule } = useSelector((state) => state)
	const dispatch = useDispatch()
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [open, setOpen] = useState(false)

	const [loading, setLoading] = useState(false)

	const dialog = useMemo(() => {
		return {
			title: 'Geofence delete',
			message: t('common:misc.areYouSureYouWantToDelete') + ` ${geofence.name} ?`,
		}
	}, [geofence])

	const handleClick = (e) => {
		e.preventDefault()
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleDeleteGeofence = () => {
		setLoading(true)
		let hasError = false
		geofenceApi
			.deleteGeofence(geofence.key, environment?.apiToken)
			.then((res) => {
				dispatch({
					type: GEOFENCES_ACTIONS.DELETED_GEOFENCE,
					payload: geofence,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
				hasError = true
			})
			.finally(() => {
				setLoading(false)
				handleClose()
				if (hasError) return
				dispatch({
					type: ENV_ACTIONS.REFRESH,
					payload: true,
				})
				if (geofencesModule?.selectedGeofence?.name === geofence.name) {
					dispatch({
						type: GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE,
						payload: {},
					})
					dispatch({
						type: GEOFENCES_ACTIONS.ACTION,
						payload: null,
					})
				}
			})
	}

	return (
		<>
			<Tooltip
				title={`${t('tooltip.prefix.delete')} - ${geofence.name}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<span>
					<IconButton
						aria-label="add"
						size="small"
						color="primary"
						edge="end"
						onClick={handleClick}
					>
						<DeleteForeverIcon fontSize="inherit" className={classes.icon} />
					</IconButton>
				</span>
			</Tooltip>
			<DoformsDialog
				environment={environment}
				open={open}
				dialog={dialog}
				loading={loading}
				dialogAction={true}
				onClose={handleClose}
				onConfirm={handleDeleteGeofence}
			></DoformsDialog>
		</>
	)
}

export default GeofencesDashboard
