import { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { isEmpty, isEqual } from 'lodash'

import { GoogleMap, Polygon, Circle, useJsApiLoader, DrawingManager } from '@react-google-maps/api'
import { Box, IconButton, Tooltip } from '@mui/material'
import ShapeLineIcon from '@mui/icons-material/ShapeLine'

import { GEOFENCES_RENDER_TYPES } from '../../../utils/params/helpers'

const MAP_OPTIONS = {
	minZoom: 4,
	gestureHandling: 'greedy',
	styles: [{ featureType: 'poi' }],
}

const defaultMapState = {
	center: { lat: 33.753746, lng: -84.38633 },
	zoom: 11,
	pointZoom: 18,
}

const DRAWING_OPTIONS = {
	drawingControl: false, // Hide default drawing controls
	polygonOptions: {
		fillColor: 'blue',
		fillOpacity: 0.3,
		strokeColor: 'blue',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		editable: true,
		draggable: false,
	},
}

const GeofencesMap = (props) => {
	const {
		geofenceRenderType,
		lat,
		lng,
		radius,
		polygonPoints,
		setLat,
		setLng,
		setRadius,
		setPolygonPoints,
	} = props

	const mapRef = useRef(null)
	const circleRef = useRef(null)
	const prevCenter = useRef({ lat, lng })
	const prevRadius = useRef(radius)
	const pointsPolygonRef = useRef([])

	const isCircle = useMemo(
		() => geofenceRenderType === GEOFENCES_RENDER_TYPES.CIRCLE,
		[geofenceRenderType]
	)

	const formattedPolygonPoints = useMemo(() => {
		if (!polygonPoints?.length) return []
		return polygonPoints.map((p) => ({ lat: p.lat, lng: p.lon }))
	}, [polygonPoints])

	const isValidCircle = useMemo(
		() =>
			isCircle &&
			Number.isFinite(lat) &&
			Number.isFinite(lng) &&
			Number.isFinite(radius) &&
			radius > 0,
		[isCircle, lat, lng, radius]
	)

	const [mapCenter, setMapCenter] = useState(defaultMapState.center)
	const [zoom, setZoom] = useState(defaultMapState.zoom)

	const [circleCenter, setCircleCenter] = useState(isValidCircle ? { lat, lng } : {})

	const [isDrawing, setIsDrawing] = useState(false)

	const fitBounds = useCallback(
		(map, center, radius, polygonCoords) => {
			if (!map || !window.google?.maps) return

			const bounds = new window.google.maps.LatLngBounds()

			if (isCircle && isValidCircle) {
				// Extend bounds for the circle
				const circleCenter = new window.google.maps.LatLng(center.lat, center.lng)

				;[0, 90, 180, 270].forEach((angle) => {
					const point = window.google.maps.geometry.spherical.computeOffset(
						circleCenter,
						radius,
						angle
					)
					bounds.extend(point)
				})
			} else if (!isCircle && polygonCoords?.length > 0) {
				// Extend bounds for the polygon
				polygonCoords.forEach(({ lat, lon }) => {
					bounds.extend(new window.google.maps.LatLng(lat, lon))
				})
			} else {
				setMapCenter(center)
				if (isEqual(center, defaultMapState.center)) {
					map.setZoom(defaultMapState.zoom)
					setZoom(defaultMapState.zoom)
					return
				}

				map.setZoom(defaultMapState.pointZoom)
				setZoom(defaultMapState.pointZoom)
				return
			}

			map.fitBounds(bounds)

			// Prevent excessive zoom-in
			const newZoom = Math.max(map.getZoom(), 10)
			map.setZoom(newZoom)
			setZoom(newZoom)
		},
		[isCircle, isValidCircle]
	)
	useEffect(() => {
		if (!isCircle && isEmpty(polygonPoints)) {
			if (!!polygonRef.current) {
				polygonRef.current = null
			}
			setIsDrawing(true)
			return
		}
		setIsDrawing(false)
	}, [polygonPoints, isCircle])

	useEffect(() => {
		if (!isCircle) return

		if (!isValidCircle) {
			// Remove the circle if invalid
			if (circleRef.current) {
				window.google.maps.event.clearInstanceListeners(circleRef.current)
				circleRef.current.setMap(null)
				circleRef.current = null
				console.log('Circle removed due to invalid values')
			}
			return
		}

		setCircleCenter((prev) => {
			if (!isEqual(prev, { lat, lng })) {
				return { lat, lng }
			}
			return prev
		})
	}, [isCircle, lat, lng, isValidCircle])

	useEffect(() => {
		const map = mapRef.current
		if (!map) return

		const hasLatLng = Number.isFinite(lat) && Number.isFinite(lng)
		const center = hasLatLng ? { lat, lng } : defaultMapState.center

		if ((!isCircle && isEmpty(polygonPoints)) || (isCircle && !isValidCircle)) {
			setMapCenter(center)
			if (isEqual(center, defaultMapState.center)) {
				map.setZoom(defaultMapState.zoom)
				setZoom(defaultMapState.zoom)
				return
			}

			map.setZoom(defaultMapState.pointZoom)
			setZoom(defaultMapState.pointZoom)
			return
		}

		prevCenter.current = center
		fitBounds(mapRef.current, center, radius, polygonPoints)
	}, [lat, lng, radius, polygonPoints, isCircle])

	// Handle map load
	const onMapLoad = useCallback(
		(map) => {
			mapRef.current = map
			const hasLatLng = Number.isFinite(lat) && Number.isFinite(lng)
			const center = hasLatLng ? { lat, lng } : defaultMapState.center

			if ((!isCircle && isEmpty(polygonPoints)) || (isCircle && !isValidCircle)) {
				setMapCenter(defaultMapState.center)
				if (isEqual(center, defaultMapState.center)) {
					map.setZoom(defaultMapState.zoom)
					setZoom(defaultMapState.zoom)
					return
				}

				map.setZoom(defaultMapState.pointZoom)
				setZoom(defaultMapState.pointZoom)
				return
			}
			fitBounds(map, center, radius, polygonPoints)
		},
		[fitBounds, circleCenter, radius, isCircle, polygonPoints]
	)

	// Handle circle load and attach event listeners
	const onCircleLoad = useCallback(
		(circle) => {
			if (circleRef.current) {
				window.google.maps.event.clearInstanceListeners(circleRef.current)
				circleRef.current.setMap(null)
			}
			circleRef.current = circle

			circle.addListener('center_changed', () => {
				const newCenter = circle.getCenter()
				const newLat = newCenter.lat()
				const newLng = newCenter.lng()

				if (prevCenter.current.lat !== newLat || prevCenter.current.lng !== newLng) {
					prevCenter.current = { lat: newLat, lng: newLng }
					setLat(newLat)
					setLng(newLng)
				}
			})

			circle.addListener('radius_changed', () => {
				const newRadius = Math.round(circle.getRadius())
				if (prevRadius.current !== newRadius) {
					prevRadius.current = newRadius
					setRadius(newRadius)
					if (mapRef.current) {
						fitBounds(mapRef.current, circle.getCenter().toJSON(), newRadius)
					}
				}
			})
		},
		[setLat, setLng, setRadius, fitBounds, circleCenter]
	)

	const onPolygonComplete = (polygon) => {
		if (!polygon) return

		const path = polygon.getPath()
		const polygonCoords = path.getArray().map((latLng) => ({
			lat: latLng.lat(),
			lon: latLng.lng(),
		}))

		// Prevent setting the same value
		polygon.setMap(null)
		setIsDrawing(false)
		if (
			pointsPolygonRef.current.length === polygonCoords.length &&
			pointsPolygonRef.current.every(
				(point, index) =>
					point.lat === polygonCoords[index].lat && point.lon === polygonCoords[index].lon
			)
		) {
			return
		}

		// Update the ref and state
		pointsPolygonRef.current = polygonCoords
		// Remove polygon from the map (detach from DrawingManager)
		setLat(polygonCoords[0].lat)
		setLng(polygonCoords[0].lon)
		setPolygonPoints(polygonCoords)
	}

	const polygonRef = useRef(null)

	const onPolygonLoad = (polygon) => {
		polygonRef.current = polygon
	}

	const handlePolygonEdit = (event) => {
		if (!polygonRef.current) return

		const path = polygonRef.current.getPath()
		const newCoordinates = path.getArray().map((latLng) => ({
			lat: latLng.lat(),
			lon: latLng.lng(),
		}))

		setLat(newCoordinates[0].lat)
		setLng(newCoordinates[0].lon)
		setPolygonPoints(newCoordinates) // Update state with new points
	}

	return (
		<GoogleMap
			onLoad={onMapLoad}
			mapContainerStyle={{ height: '100%', width: '100%' }}
			center={mapCenter}
			zoom={zoom}
			options={MAP_OPTIONS}
		>
			{isCircle && isValidCircle && (
				<Circle
					center={circleCenter}
					radius={radius}
					onLoad={onCircleLoad}
					options={{
						fillColor: 'orange',
						fillOpacity: 0.3,
						strokeColor: 'red',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						draggable: true,
						editable: true,
					}}
				/>
			)}

			{!isCircle && isDrawing && (
				<DrawingManager
					options={DRAWING_OPTIONS}
					drawingMode={isDrawing ? 'polygon' : null}
					onPolygonComplete={onPolygonComplete}
				/>
			)}

			{!isCircle && formattedPolygonPoints.length > 0 && (
				<Polygon
					path={formattedPolygonPoints}
					onLoad={onPolygonLoad}
					options={{
						fillColor: 'blue',
						fillOpacity: 0.3,
						strokeColor: 'blue',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						editable: true,
					}}
					onMouseUp={handlePolygonEdit}
				/>
			)}
		</GoogleMap>
	)
}

export default GeofencesMap
