import { Container, Paper } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../../../custom-components/LoadingSpinner'
import DragDropLayout from '../components/DragDropLayout'
import useTileQuery from '../hooks/useTileQuery'
import { isEmpty, isEqual } from 'lodash'

const DashboardContext = React.createContext()

function DashboardProvider(props) {
	const [selectedFields, setSelectedFields] = React.useState({})
	const [currentSelected, setCurrentSelected] = React.useState('')
	const [isRemovedTile, setIsRemovedTile] = React.useState({})
	const [linkedTitle, setLinkedTitle] = React.useState({})

	// for dataGridTile { [datagridKey] : mobileUnitNumber}
	const [selectedMobileUnitNumber, setSelectedMobileUnitNumber] = React.useState({})

	// for feedTile { [mobileUnitKey] : mobileUnitNumber}
	const [selectedMobileUnitForFeedTile, setSelectedMobileUnitForFeedTile] = React.useState({})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSelectRow = (tileName, value) => {
		if (selectedFields[tileName] === value) {
			// delete key if value is the same
			const { [tileName]: _, ...rest } = selectedFields
			setSelectedFields(rest)
			return
		}
		setSelectedFields({ ...selectedFields, [tileName]: value })
	}

	const handleSelectMobileUnitForDataGridTile = (dataGridTileName, value) => {
		if (isEmpty(dataGridTileName)) return
		// const mobileNumber = value.number
		if (isEqual(selectedMobileUnitNumber[dataGridTileName], value)) {
			// delete key if value is the same
			const { [dataGridTileName]: _, ...rest } = selectedMobileUnitNumber
			setSelectedMobileUnitNumber(rest)
			return
		}
		setSelectedMobileUnitNumber({ ...selectedMobileUnitNumber, [dataGridTileName]: value })
	}

	const handleSelectMobileUnitForFeedTile = (mobileUnitTileKey, value) => {
		if (isEmpty(mobileUnitTileKey)) return
		// const mobileNumber = value.number
		if (isEqual(selectedMobileUnitForFeedTile[mobileUnitTileKey], value)) {
			// delete key if value is the same
			const { [mobileUnitTileKey]: _, ...rest } = selectedMobileUnitForFeedTile
			setSelectedMobileUnitForFeedTile(rest)
			return
		}
		setSelectedMobileUnitForFeedTile({
			...selectedMobileUnitForFeedTile,
			[mobileUnitTileKey]: value,
		})
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSelectCurrent = (value) => {
		setCurrentSelected(value)
	}

	function resetSelectedMobileNumber() {
		setSelectedMobileUnitNumber({})
	}

	function onSelectMobileUnit(mobileUnitTileKey, dataGridTileName, value) {
		handleSelectMobileUnitForDataGridTile(dataGridTileName, value)
		handleSelectRow(mobileUnitTileKey, value)
		handleSelectMobileUnitForFeedTile(mobileUnitTileKey, value)
	}

	function onSetLinkedTitle(dataGridTileName, value) {
		if (isEmpty(dataGridTileName)) return
		if (isEqual(linkedTitle[dataGridTileName], value)) return
		setLinkedTitle({ ...linkedTitle, [dataGridTileName]: value })
	}

	const value = React.useMemo(
		() => ({
			selectedFields,
			onSelectRow: handleSelectRow,
			currentSelected,
			onSelectCurrent: handleSelectCurrent,
			isRemovedTile,
			setIsRemovedTile,
			selectedMobileUnitNumber,
			selectedMobileUnitForFeedTile,
			onSelectMobileUnit,
			resetSelectedMobileNumber,
			linkedTitle,
			onSetLinkedTitle,
		}),
		[
			selectedFields,
			handleSelectRow,
			currentSelected,
			handleSelectCurrent,
			isRemovedTile,
			selectedMobileUnitNumber,
		]
	)
	return <DashboardContext.Provider value={value} {...props} />
}

export function useTileDashboard() {
	const context = React.useContext(DashboardContext)
	if (!context) {
		throw new Error('useTileDashboard must be used within a DashboardProvider')
	}
	return context
}

const DashboardLayout = () => {
	const { id: dashboardKey } = useParams()
	const { blockedTileListWithPosition, isLoading: isTileLoading } = useTileQuery({ dashboardKey })
	if (isTileLoading) return <LoadingSpinner />

	if (blockedTileListWithPosition.length === 0) return null

	return (
		<DashboardProvider>
			<Container maxWidth={false} sx={{ my: 1, px: '8px !important' }}>
				<Paper elevation={3} sx={{ width: '100%', minHeight: '80dvh' }}>
					{blockedTileListWithPosition?.length > 0 && (
						<DragDropLayout layout={blockedTileListWithPosition} isOnDashboard />
					)}
				</Paper>
			</Container>
		</DashboardProvider>
	)
}

export default DashboardLayout
