import { useRef, useState, useContext, useMemo, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import moment from 'moment'

import {
	Button,
	Paper,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'

import { datesValues, nonDatesValues, operatorOptions } from 'utils/functions/conditionsOptions'

import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
}))

const operatorMap = {
	EQ: '=',
	NE: '!=',
}

const PrintDataGrid = ({
	printTitle,
	gridColumns,
	rows,
	viewData,
	aggregationModel,
	rowGroupingModel,
}) => {
	const { t } = useTranslation()
	const { environment } = useSelector((state) => state)

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const printRef = useRef()
	const [openDialog, setOpenDialog] = useState(false)
	const [orientation, setOrientation] = useState('portrait')
	const [paperSize, setPaperSize] = useState('A4')

	const userOffset = useMemo(() => {
		return environment.userCurrent.time.timezone.offset || 0
	}, [environment])

	const operators = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	const dateValues = useMemo(() => {
		return datesValues(t)
	}, [t])

	function buildDateTimeValueByColumnType(value, columnType) {
		switch (columnType) {
			case 'datetime':
				return moment.utc(value).utcOffset(userOffset).format('MM/DD/YYYY HH:mm:ss')
			case 'date':
				return moment.utc(value).utcOffset(userOffset).format('MM/DD/YYYY')
			case 'time':
				return moment.utc(value).utcOffset(userOffset).format('HH:mm:ss')
			default:
				return value
		}
	}

	const nonDateValues = useMemo(() => {
		return nonDatesValues(t)
	}, [t])

	const queryString = useMemo(() => {
		if (isEmpty(viewData)) return ''

		const conditions = viewData?.queries[0]?.filter.conditions || []
		const query = conditions.map((condition) => {
			const { target, preset, type, values } = condition
			const column = gridColumns.find((item2) => item2.name === target)
			let field = column?.title
			const columnType = column?.type?.toLowerCase()
			if (!field) {
				field = target
			}
			let ope = operatorMap[type]
			if (!ope) {
				ope = operators.find((item2) => item2.type === type)?.name
			}
			if (!ope) {
				ope = type
			}
			let val = ''
			if (preset) {
				val = dateValues?.find((item2) => item2.preset === preset)?.name
				if (!val) {
					val = nonDateValues?.find((item2) => item2.preset === preset)?.name
				}
				if (!val) {
					val = preset
				}
			} else if (values) {
				if (Array.isArray(values) && values.length > 1) {
					val =
						' [ ' +
						values
							?.map((value) => {
								return buildDateTimeValueByColumnType(value, columnType)
							})
							.join(' ; ') +
						' ]'
				} else {
					val = buildDateTimeValueByColumnType(values[0], columnType)
				}
			}
			return field + ' ' + ope + ' ' + val
		})

		if (query && query.length > 0) {
			let tmpFilter = query.filter((x) => x !== '')
			return tmpFilter?.join(', ')
		}

		if (!query) {
			return ' '
		}
	}, [viewData])

	const [isPrinting, setIsPrinting] = useState(false)

	const [gridHeight, setGridHeight] = useState(undefined)
	const gridRef = useRef(null)

	const promiseResolveRef = useRef(null)
	useEffect(() => {
		if (isPrinting && promiseResolveRef.current) {
			promiseResolveRef.current()
		}
	}, [isPrinting])

	useEffect(() => {
		console.log('Rows:', rows.length)
		if (printRef.current) {
			const childElement = printRef.current.querySelector('.MuiDataGrid-root')

			if (childElement) {
				const childHeight = childElement.offsetHeight
				console.log('Grid Child height:', childHeight)
				if (childElement.offsetHeight !== gridHeight) {
					setGridHeight(childElement.offsetHeight + 52)
				}
			}
		}
	}, [rows, printRef.current])

	const pageStyle = useMemo(() => {
		return `
			@page {
				size: ${paperSize} ${orientation}; 
			}

			.MuiDataGrid-virtualScroller,
			.MuiDataGrid-virtualScrollerContent {
				height: fit-content !important;
				overflow: visible !important;
			}

			.MuiDataGrid-root{
				overflow: visible !important;
				max-height: none !important;
				height: ${gridHeight}px !important;
			}

			.MuiDataGrid-row {
				page-break-inside: avoid !important;
			}
		`
	}, [paperSize, orientation, gridHeight])

	const title = useMemo(() => {
		return printTitle || viewData?.name
	}, [printTitle, viewData])

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: title,
		pageStyle,
		onBeforePrint: () => {
			return new Promise((resolve) => {
				const style = document.createElement('style')
				style.media = 'print'
				style.innerHTML = `
					@page {
						size: ${paperSize} ${orientation}; 
					}

					.MuiPaper-root,
					.MuiDataGrid-main,
					.MuiDataGrid-virtualScroller,
					.MuiDataGrid-virtualScrollerContent {
						height: auto !important;
						overflow: visible !important;
						max-height: none !important;
					}

					.MuiDataGrid-root{
						overflow: visible !important;
						max-height: none !important;
						height: ${gridHeight}px !important;
					}

					.MuiDataGrid-row {
						page-break-inside: avoid !important;
					}
				`
				document.head.appendChild(style)

				setTimeout(() => {
					promiseResolveRef.current = resolve
					setIsPrinting(true)
				}, 1000)
			})
		},
		onAfterPrint: () => {
			// Reset the Promise resolve so we can print again
			promiseResolveRef.current = null
			setIsPrinting(false)
			setOpenDialog(false)
		},
	})

	return (
		<div>
			<MenuItem onClick={() => setOpenDialog(true)}>PDF</MenuItem>
			<Dialog
				sx={{
					'& .MuiDialog-paper': {
						width: '35% !important',
						maxWidth: '100% !important',
					},
				}}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
			>
				<DialogTitle>Print {printTitle || viewData?.name}</DialogTitle>
				<DialogContent>
					<p>Select your print options:</p>
					<FormControl fullWidth margin="dense">
						<InputLabel id="orientation-select-small-label">Orientation</InputLabel>
						<Select
							size="small"
							labelId="orientation-select-small-label"
							id="orientation-select-small"
							label={'Orientation'}
							value={orientation}
							onChange={(e) => setOrientation(e.target.value)}
						>
							<MenuItem value="portrait">Portrait</MenuItem>
							<MenuItem value="landscape">Landscape</MenuItem>
						</Select>
					</FormControl>
					{/* <FormControl fullWidth margin="dense">
						<InputLabel id="pagesize-select-small-label">Paper Size</InputLabel>
						<Select
							labelId="pagesize-select-small-label"
							id="pagesize-select-small"
							label={'Paper Size'}
							value={paperSize}
							onChange={(e) => setPaperSize(e.target.value)}
						>
							<MenuItem value="A4">A4</MenuItem>
							<MenuItem value="letter">Letter</MenuItem>
							<MenuItem value="legal">Legal</MenuItem>
						</Select>
					</FormControl> */}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)} className={classes.icon}>
						{t('common:misc.cancel')}
					</Button>
					<LoadingButton
						className={classes.icon}
						style={{
							minWidth: '80px',
						}}
						// disabled={
						// 	recordsLoading ||
						// 	(listActiveFields !== null && listActiveFields.length === 0) ||
						// 	listActiveFields === null
						// }
						loadingPosition="start"
						onClick={() => {
							handlePrint()
							setOpenDialog(false)
						}}
					>
						{t('common:misc.exportPrint')}
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<div
				style={{
					position: 'absolute',
					top: '-9999px',
					left: '-9999px',
					visibility: 'hidden',
				}}
			>
				<Paper
					style={{
						overflow: 'visible',
						display: 'block',
						width: '100%',
						height: 'auto',
					}}
					ref={printRef}
				>
					<div style={{ borderBottom: '2px solid black', marginBottom: '10px' }}>
						<h2>{title}</h2>
						<div>{queryString}</div>
						<div>{`${environment.userCurrent.firstName} ${environment.userCurrent.lastName} - ${environment.userCurrent.email}`}</div>
					</div>

					{/* <div
						className="print-datagrid-container"
						style={{
							height: `${gridHeight}px`, // Dynamically set the height based on rows
							overflowY: 'auto',         // Allow scrolling if needed
							display: 'block',          // Ensure the container is a block element
						  }}
					> */}
					<div
						style={{
							height: `${gridHeight}px`,
						}}
					>
						<DataGridPremium
							ref={gridRef}
							rows={rows}
							columns={gridColumns}
							aggregationModel={aggregationModel}
							rowGroupingModel={rowGroupingModel}
							disableVirtualization={true}
							rowHeight={25}
							getRowHeight={() => 'auto'}
							defaultGroupingExpansionDepth={1}
							autoPageSize
						/>
					</div>

					<p style={{ marginTop: '10px', borderTop: '2px solid black', paddingTop: '10px' }}>
						{moment
							.utc()
							.utcOffset(userOffset || 0)
							.format('MM/DD/YYYY HH:mm:ss')}
					</p>
				</Paper>
			</div>
		</div>
	)
}

export default PrintDataGrid
