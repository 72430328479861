const initialState = {
	action: null,
	hasLoadGeofences: false,
	sharedGeofences: [],
	mobileUnitGeofences: [],
	selectedGeofence: null,
	createdGeofence: null,
	updateGeofence: null,
	deletedGeofence: null,
}

export const GEOFENCES_ACTIONS_TYPE = {
	ADD_NEW: 'addNew',
	EDIT: 'edit',
}

export const GEOFENCES_ACTIONS = {
	ACTION: 'ACTION',
	SET_INIT_GEOFENCES: 'SET_INIT_GEOFENCES',
	SET_HAS_LOAD_GEOFENCES: 'SET_HAS_LOAD_GEOFENCES',
	SET_SHARED_GEOFENCES: 'SET_SHARED_GEOFENCES',
	SET_MOBILE_UNIT_GEOFENCES: 'SET_MOBILE_UNIT_GEOFENCES',
	SET_SELECTED_GEOFENCE: 'SET_SELECTED_GEOFENCE',
	UPDATE_GEOFENCE: 'UPDATE_GEOFENCE',
	CREATE_GEOFENCE: 'CREATE_GEOFENCE',
	DELETED_GEOFENCE: 'DELETED_GEOFENCE',
}

const geofencesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GEOFENCES_ACTIONS.ACTION:
			return {
				...state,
				action: action.payload,
			}
		case GEOFENCES_ACTIONS.SET_INIT_GEOFENCES:
			return {
				...state,
				sharedGeofences: action.payload.sharedGeofences,
				mobileUnitGeofences: action.payload.mobileUnitGeofences,
			}
		case GEOFENCES_ACTIONS.SET_SHARED_GEOFENCES:
			return {
				...state,
				sharedGeofences: action.payload,
			}
		case GEOFENCES_ACTIONS.SET_MOBILE_UNIT_GEOFENCES:
			return {
				...state,
				mobileUnitGeofences: action.payload,
			}
		case GEOFENCES_ACTIONS.SET_SELECTED_GEOFENCE:
			return {
				...state,
				selectedGeofence: action.payload,
			}
		case GEOFENCES_ACTIONS.CREATE_GEOFENCE:
			return {
				...state,
				createdGeofence: action.payload,
			}
		case GEOFENCES_ACTIONS.UPDATE_GEOFENCE:
			return {
				...state,
				updatedGeofence: action.payload,
			}
		case GEOFENCES_ACTIONS.DELETED_GEOFENCE:
			return {
				...state,
				deletedGeofence: action.payload,
			}

		default:
			return state
	}
}

export default geofencesReducer
