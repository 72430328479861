import { memo, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import CollapseComponent from './CollapseComponent'

import { WIZARD_VIEW_TYPES, FEED_TYPES, shouldPreventRender } from '../ViewDialogUtils'

function ChooseATypeComponent(props) {
	const { t } = useTranslation()
	const { setExpandedAccordion, expandedAccordion, isAddNew, viewType, feedType, setFeedType, resetData } =
		props

	const title = useMemo(() => 'Choose a type', [t])
	const [value, setValue] = useState('')

	const feedTypeOptions = useMemo(() => {
		if (viewType !== WIZARD_VIEW_TYPES.FEED) {
			return []
		}

		return Object.values(FEED_TYPES).map((type) => {
			if (type === FEED_TYPES.FEED_GPS) {
				return {
					value: type,
					label: 'Time status events, stops, GPS tracks',
				}
			}

			return {
				value: type,
				label: 'Form submissions, dispatch status events, geofence events,  time status events',
			}
		})
	}, [viewType])

	function handleFeedViewTypeChange(event) {
		setFeedType(event.target.value)
        resetData()
	}

	useEffect(() => {
		switch (feedType) {
			case FEED_TYPES.FEED_GPS:
				setValue('Time status events, stops, GPS tracks')
				break
			case FEED_TYPES.FEED:
				setValue('Form submissions, dispatch status events, geofence events, time status events')
				break
			default:
				break
		}
	}, [feedType])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<FormControl
					variant="outlined"
					sx={{
						width: '100% !important',
						marginTop: '5px',
						marginBottom: '5px',
					}}
					size="small"
					disabled={!isAddNew}
				>
					<InputLabel id="feedType-select-small-label">{t('common:input.type')}</InputLabel>
					<Select
						labelId="feedType-select-small-label"
						id="feedType-select-small"
						value={feedType}
						label={t('common:input.type')}
						onChange={handleFeedViewTypeChange}
					>
						<MenuItem value=""></MenuItem>
						{feedTypeOptions.map((option) => (
							<MenuItem value={option.value} key={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			}
		/>
	)
}

export const ChooseATypeCollapse = memo(ChooseATypeComponent, shouldPreventRender)
